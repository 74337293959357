<script setup>
import {ref, computed, defineAsyncComponent} from 'vue'
import AppLayout from '@/Layout/AppLayout.vue'
import SeoFreeTool from '@/Components/General/SeoFreeTool.vue'
import HowWork from '@/Components/General/HowWork.vue'
import HowBuy from '@/Components/General/HowBuy.vue'
import FreeToolSubmitForm from "@/Components/General/FreeToolSubmitForm.vue";
import {trans} from "@/Utils/translation.utils.js";
import {usePage} from '@inertiajs/vue3'

const props = defineProps({
    freeTool: Object,
    content: [Object, null],
});

const seoData = usePage().props.seo_data
const inputField = ref(props.freeTool.data.input_field);
const viewPath = computed(() => props.freeTool.data.view_path);
const buttonText = ref(null);

const DynamicComponent = computed(() => {
    return defineAsyncComponent(() => import(`@/Components/General/FreeTools/${viewPath.value}.vue`));
});

</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{ seoData.h1_title }}</h1>
                <div class="pb-5">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <FreeToolSubmitForm :inputField="inputField" :freeToolId="freeTool.data.id"
                                                :buttonText="buttonText ?? trans('front.free-tools-button-download')"/>
                        </div>
                    </div>
                </div>
                <div v-if="DynamicComponent" class="pb-5">
                    <component :is="DynamicComponent" :content="content"
                               @set-button-text="buttonText = $event.buttonText"/>
                </div>
                <SeoFreeTool/>
            </div>
            <HowWork/>
            <HowBuy/>
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
