<script setup>
import {usePage} from '@inertiajs/vue3'

const seoData = usePage().props.seo_data
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-7">
            <div class="text-center" v-html="seoData.top_text"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
