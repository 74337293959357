<script setup></script>

<template>
  <div class="modal fade" id="discountModal" tabindex="-1" aria-labelledby="discountModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button class="btn" type="button" data-bs-dismiss="modal" aria-label="Close">
            <img src="/img/icons/close.svg" alt="Close icon" width="16" height="16" />
          </button>
          <p>You can use your coupon if you have one.</p>
          <p>Fill in the email and username fields, then click on the checkbox to open the coupon input field.</p>
          <p>
            In the email field, please type in the email where you received your coupon. Coupon is connected to your
            email.
          </p>
          <p>
            Enter the coupon and click update - if your coupon is active - the price of the product will decrease with a
            corresponding discount.
          </p>
          <p>
            The coupon is valid for one purchase. After pressing Get It Now your coupon is activated. You can only
            activate it once, choose carefully.
          </p>
          <img src="/img/coupon_popup_example.png" alt="Coupon example" width="500" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .modal-dialog {
    width: 100%;
    max-width: 37.5rem;

    .btn {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }
  }
</style>
