<script setup>
import {trans} from "@/Utils/translation.utils.js";

const items = [
    {
        iconUrl: '/img/icons/how-buy-1.svg',
        title: trans('front.how-buy-item-title-1'),
        content: trans('front.how-buy-item-content-1'),
    },
    {
        iconUrl: '/img/icons/how-buy-2.svg',
        title: trans('front.how-buy-item-title-2'),
        content: trans('front.how-buy-item-content-2'),

    },
    {
        iconUrl: '/img/icons/how-buy-3.svg',
        title: trans('front.how-buy-item-title-3'),
        content: trans('front.how-buy-item-content-3'),

    }
]
</script>

<template>
  <section class="how-buy">
    <div class="container">
      <h2 class="how-buy__title"> {{trans('front.how-buy-h2')}} </h2>
      <div class="how-buy__subtitle">
          {{trans('front.how-buy-text')}}
      </div>
      <div class="how-buy__content">
        <div class="row g-4 align-items-stretch">
          <div v-for="(item, index) in items" :key="`how-buy-${index}`" class="col-12 col-xl-4">
            <div :class="`how-buy-item how-buy-item--${index + 1} h-100`">
              <div class="how-buy-item__img">
                <img :src="item.iconUrl" :alt="item.title" width="129" height="86" loading="lazy">
              </div>
              <div class="row g-0">
                <div class="col-10">
                  <h3 class="how-buy-item__title">
                    {{ item.title }}
                  </h3>
                  <div class="how-buy-item__content">
                    {{ item.content }}
                  </div>
                </div>
                <div class="col-2">
                  <div class="how-buy-item__number">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .how-buy {
    padding: 3rem 0;
    border-top: 0.0625rem solid #c8d4e0;
    text-align: center;

    &__title {
      font-weight: 800;
      margin-bottom: 1.5rem;
    }

    &__subtitle {
      font-size: 1.25rem;
      max-width: 53.75rem;
      margin: 0 auto 1.5rem;
    }
  }

  .how-buy-item {
    position: relative;
    overflow: hidden;
    min-height: 12.1875rem;
    padding: 1.5rem;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
    color: #fff;
    text-align: left;

    &--1 {
      background-color: #a1afcc;
    }

    &--2 {
      background-color: #2d4472;
    }

    &--3 {
      background-color: #141e33;
    }

    &__img {
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: 8.125rem;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.25rem;
      height: 3.25rem;
      background-color: #fff;
      border-radius: 50%;
      margin-left: auto;

      span {
        font-size: 1.25rem;
        font-weight: 800;
        color: #2c4370;
      }
    }

    &__content {
      position: relative;
    }
  }
</style>
