<script setup>
  import SeoTextService from '@/Components/General/SeoTextService.vue'
  import WhyChoose from '@/Components/General/WhyChoose.vue'
  import HowWork from '@/Components/General/HowWork.vue'
  import HowBuy from '@/Components/General/HowBuy.vue'
  import AppLayout from "@/Layout/AppLayout.vue";
  import {Link, usePage} from '@inertiajs/vue3'
  import {trans} from "@/Utils/translation.utils.js";

  const props = defineProps({
      items: [Array,Object],
      buttonColor: {
        type:String,
        default:null
        }
  })

  const seoData = usePage().props.seo_data

</script>

<template>
  <AppLayout>
      <section class="service-page">
          <div class="container">
              <h1 class="page-title mb-4">{{seoData.h1_title}}</h1>
              <div class="service-list mb-5">
                  <div class="row justify-content-center align-items-stretch g-4">
                      <div v-for="item in items.data" :key="item.title" class="col-12 col-md-6 col-lg-4 col-xl-3">
                          <Link :href="item.link" class="service-list-item c-card c-card--hovered">
                              <img class="service-list-item__img mb-3" :src="item.icon" :alt="item.alt" width="72" />
                              <span class="service-list-item__title mb-3">{{ item.name }}</span>
                              <button class="service-list-item__btn btn btn-outline-primary" :class="buttonColor">{{trans('front.view-pricing')}}</button>
                          </Link>
                      </div>
                  </div>
              </div>

              <SeoTextService />
          </div>

          <WhyChoose />
          <HowWork />
          <HowBuy />
      </section>
  </AppLayout>
</template>

<style lang="scss" scoped>
  .service-page {
    padding-top: 1.25rem;
    .service-list-item {
      min-height: 252px;
    }
  }

  .service-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5rem 1rem;
    font-family: 'Ruda', 'Montserrat', sans-serif;
    height: 100%;

    &:hover {
      text-decoration: none;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 800;
    }

    &__btn {
      transition: none;
      &:hover {
        border-color: #1e73be;
        background-color: #1e73be;
      }
    }
  }
</style>
