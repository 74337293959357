<script setup>
import {trans} from "@/Utils/translation.utils.js";
  const items = [
    {
      iconUrl: '/img/icons/how-work-1.svg',
      title:trans('front.how-work-item-title-1')
    },
    {
      iconUrl: '/img/icons/how-work-2.svg',
        title:trans('front.how-work-item-title-2')
    },
    {
      iconUrl: '/img/icons/how-work-3.svg',
        title:trans('front.how-work-item-title-3')
    },
    {
      iconUrl: '/img/icons/how-work-4.svg',
        title:trans('front.how-work-item-title-4')
    }
  ]
</script>

<template>
  <section class="how-work">
    <div class="container">
      <div class="how-work__wrapper">
        <h2 class="how-work__title">{{trans('front.how-work-h2')}}</h2>
        <div class="how-work__content">
          <div v-for="(item, index) in items" :key="`how-work-${index}`" class="how-work-item">
            <div class="row align-items-center">
              <div class="col-6 col-md-3">
                <div class="how-work-item__img">
                  <img :src="item.iconUrl" :alt="item.title" width="156" height="145" loading="lazy">
                </div>
              </div>
              <div class="col">
                <p class="how-work-item__title" v-html="item.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .how-work {
    padding: 3rem 0;
    border-top: 0.0625rem solid #c8d4e0;

    &__wrapper {
      max-width: 54.375rem;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }

  .how-work-item {
    position: relative;
    padding: 1rem 0;
    border-radius: 2rem;

    &::before {
      content: '';
      position: absolute;
      border: 0.125rem dotted #d1d4d7;
    }

    &:nth-child(odd)::before {
      top: -0.125rem;
      bottom: -0.125rem;
      left: 4.375rem;
      width: 1.25rem;
      border-right: 0;
      border-radius: 1.25rem 0 0 1.25rem;
    }

    &:nth-child(even)::before {
      top: 0;
      bottom: 0;
      right: 10%;
      left: 5.625rem;
      border-left: 0;
      border-radius: 0 1.25rem 1.25rem 0;
    }

    &:first-child::before {
      border-radius: 0 0 0 1.25rem;
      border-top: 0;
    }

    &:last-child::before {
      border-bottom: 0;
      border-radius: 0 1.25rem;
    }

    &:nth-child(even) {
      .row {
        flex-direction: row-reverse;
      }
    }

    &__img {
      position: relative;
      max-width: 9.75rem;
    }

    &__title {
      font-size: 1.25rem;
      margin: 0;
    }
  }

  @media (max-width: 991px) {
    .how-work-item {
      &:nth-child(even)::before {
        right: 6%;
      }
    }
  }

  @media (max-width: 767px) {
    .how-work-item {
      &:nth-child(even)::before {
        right: 29%;
        left: 3.75rem;
      }
    }
    .how-work-item {
      &:nth-child(odd)::before {
        left: 2.5rem;
      }
    }
  }
</style>
