<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import ReCaptcha from '@/Components/Partials/ReCaptcha.vue';

const loading = ref(false);
const props = defineProps({
    inputField: Object,
    freeToolId: Number,
    buttonText: [String, null],
});
const form = useForm({
    [props.inputField.name]: '',
    recaptchaToken: ''
});
const formErrors = ref({});
const validationForm = ref();

const handleToken = (token) => {
    formErrors.value.recaptchaToken = '';
    form.recaptchaToken = token;
};
const handleExpired = () => {
    formErrors.value.recaptchaToken = trans('front_payment.validation.recaptcha_expired');
};

function handleSubmit() {
    loading.value = true;
    formErrors.value = '';
    validationForm.value.classList.add('was-validated');

    form.post(route('free-tools.handle', { freeTool: props.freeToolId }), {
        preserveState: true,
        onSuccess: () => {
            validationForm.value.classList.remove('was-validated');
        },
        onError: (errors) => {
            formErrors.value = errors;
            validationForm.value.classList.add('was-validated');
        },
        onFinish:() => {
            loading.value = false;
        },
    });
}

</script>

<template>
    <div class="c-card p-4">
        <form ref="validationForm" class="needs-validation mb-3" novalidate @submit.prevent="handleSubmit">
            <div class="input-group has-validation mb-3">
                <input
                    :type="inputField.type || 'text'"
                    class="form-control form-control-lg text-center"
                    :placeholder="inputField.placeholder || ''"
                    v-model="form[inputField.name || 'inputField']"
                    :class="{
                        'is-invalid': formErrors[inputField.name],
                        'is-valid': form[inputField.name] && !formErrors[inputField.name]
                    }"
                    :required="inputField.required || false"
                    :id="inputField.name || 'inputField'"
                />
                <div v-if="formErrors[inputField.name]" class="invalid-feedback">
                    {{ formErrors[inputField.name] }}
                </div>
            </div>

            <div class="row g-0 justify-content-center has-validation mb-3">
                <div class="col-6">
                    <ReCaptcha @tokenVerified="handleToken" @tokenExpired="handleExpired" :error="formErrors.recaptchaToken"/>
                </div>
            </div>

            <div class="d-grid">
                <div class="d-grid">
                    <button class="btn btn-lg btn-success w-full" type="submit" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="!loading"> {{ buttonText }}</span>
                        <span v-if="loading"> Processing...</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

