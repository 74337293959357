export function getSelectedService(serviceList, service) {
    return serviceList.find(srv => srv.options.find(option => option.value === service));
}

export function getQuantityList(serviceList, service) {
    const selectedService = getSelectedService(serviceList, service);

    if (selectedService) {
        const selectedOption = selectedService.options.find(option => option.value === service);
        if (selectedOption) {
            return selectedOption.prices.map(price => ({
                value: price.price_id,
                label: price.quantity,
            }));
        }
    }

    return [];
}

export function getPrice(serviceList, service, quantity) {
    const selectedService = getSelectedService(serviceList, service);

    if (selectedService) {
        const selectedOption = selectedService.options.find(opt => opt.value === service);
        const priceObj = selectedOption?.prices.find(price => price.price_id === quantity);
        return priceObj ? parseFloat(priceObj.price) : 0;
    }

    return 0;
}
