<script setup>
    import { ref, onMounted, computed} from 'vue'
    import {trans} from "@/Utils/translation.utils.js";
    import {Link} from '@inertiajs/vue3'
    import PayModal from "@/Components/Modals/PayModal.vue";

    const props = defineProps({
        item: Object,
        discount: Number,
        linkTerms: String,
        form: Object,
        paymentLoading: Boolean
    })

    const currentItemId =  props.item.id || null;
    const loading = ref(false);
    const bootstrap =  ref();
    const validationForm = ref()
    const emit = defineEmits(['paymentSubmit']);

    function handleSubmit() {
        loading.value = true;
        props.form.clearErrors();
        props.form.post(route('payment.validate_customer_data'), {
            preserveState: true,
            onSuccess: () => {
                openModalPayment()
                validationForm.value.classList.remove('was-validated');
            },
            onError: (errors) => {
                validationForm.value.classList.add('was-validated');
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }

    function handlePaymentSubmitEvent(selectedPayment, itemId) {
        emit('paymentSubmit', selectedPayment, itemId);
    }

    const openModalPayment = () => {
        if (typeof window !== 'undefined' && bootstrap.value) {
            let modalPayment = new bootstrap.value.Modal(document.getElementById(`paymodal-${props.item.id}`), {
                keyboard: false
            })
            modalPayment.show();
        }
    };

    onMounted(async () => {
        bootstrap.value = await import('bootstrap');
    });
</script>

<template>
    <div class="discount-card border border-gray rounded-4 shadow position-relative overflow-hidden">
        <div class="discount-card__profit border border-2 border-danger rounded-top-4 rounded-bottom-4 rounded-end-0 rounded-start-0 position-absolute top-0 start-0 overflow-hidden text-center lh-sm">
            <div class="bg-danger p-1 text-white">
                <strong>-{{ discount }}%</strong>
            </div>
            <div class="py-2 px-1 text-danger">
                <strong class="d-block">You <br /> save</strong>
                <strong class="d-block">${{ item.entity.priceInfo.savePrice }}</strong>
            </div>
        </div>
        <div class="p-4">
            <div class="d-flex flex-column align-items-center">
                <div class="py-2 text-center">
                    <img class="mx-auto mb-2" :src="item.image" alt="Discount" width="72" height="72" />
                    <h5 class="discount-card__title mb-0">{{ item.entity.title }}</h5>
                </div>

                <div v-if="item.entity.content" class="pt-2 text-center">
                    <p class="fs-5 lh-sm mb-2" v-for="text in item.entity.content" :key="text">{{ text }}</p>
                </div>

                <div class="py-2 px-3 border border-2 border-success rounded-pill text-success lh-sm">
                    <strong>{{ item.sold }} {{trans('front.discount.sold')}}</strong>
                </div>

                <div class="py-4 text-center">
                    <h2 class="discount-card__price fw-normal lh-sm text-dark mb-0">${{ item.entity.priceInfo.discountPrice }}</h2>
                    <h4 class="discount-card__priceless fs-5 text-decoration-line-through fw-normal lh-sm mb-0">${{ item.entity.priceInfo.price }}</h4>
                </div>

                <div>

                    <form ref="validationForm" class="needs-validation mb-3" novalidate @submit.prevent="handleSubmit">
                        <div class="input-group has-validation mb-3">
                            <input
                                type="email"
                                class="form-control form-control-lg text-center"
                                :placeholder="trans('front.input_placeholder_email')"
                                v-model="props.form.customerEmail"
                                required
                            />
                            <div v-if="form.errors['customerEmail']" class="invalid-feedback">
                                {{ form.errors['customerEmail'] }}
                            </div>
                            <div v-if="form.errors.customerData" class="invalid-feedback">
                                {{ form.errors.customerData }}
                            </div>
                        </div>
                         <div v-for="(item) in item.entity.fields" :key="item.id" class="mb-3">
                            <div class="input-group has-validation mb-3">
                                <input
                                    :type="item.type || 'text'"
                                    class="form-control form-control-lg text-center"
                                    :placeholder="item.placeholder"
                                    v-model="props.form.customerData[item.id].value"
                                    :required="item.required || false"
                                />

                                <div v-if="form.errors[`customerData.${item.id}.value`]" class="invalid-feedback">
                                    {{ form.errors[`customerData.${item.id}.value`] }}
                                </div>
                            </div>
                        </div>
                        <div class="d-grid">
                            <button v-if="item.entity.paymentSystemList.length" class="btn btn-lg btn-success w-full" type="submit" :disabled="loading">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="!loading"> {{trans('front.get-it-now')}}</span>
                                <span v-if="loading"> Processing...</span>
                            </button>
                        </div>
                    </form>

                    <div class="py-2 text-center">
                        <p class="fs-sm mb-0">
                            {{trans('front.term_part_1')}}
                            <Link target="_blank" class="service-card__terms-link" :href="linkTerms" >{{trans('front.term_part_2')}}</Link>
                        </p>
                    </div>

                    <div class="py-2">
                        <div class="d-flex flex-wrap justify-content-center align-items-center gap-2">
                            <img src="/img/icons/mastercard.svg" alt="Mastercard" width="50" />
                            <img src="/img/icons/visa.svg" alt="Visa" width="50" />
                            <img src="/img/icons/amex.svg" alt="Amex" width="50" />
                            <img src="/img/icons/discover.svg" alt="Discover" width="50" />
                            <img src="/img/icons/applepay.svg" alt="Applepay" width="50" />
                            <img src="/img/icons/googlepay.svg" alt="Google pay" width="50" />
                            <img src="/img/icons/bitcoin.svg" alt="Bitcoin" width="50" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <PayModal
        :paymentSystemList="item.entity.paymentSystemList"
        :loading="paymentLoading"
        :formErrors="form.errors"
        :itemId="currentItemId"
        :id="`paymodal-${item.id}`"
        :key="`paymodal-${item.id}`"
        @submitForm="handlePaymentSubmitEvent"
    />
</template>

<style lang="scss" scoped>
    .discount-card {
        &__profit {
            width: 4.375rem;
        }

        &__title {
            font-family: 'Ruda', 'Montserrat', sans-serif;
            font-weight: 800;
        }

        &__price {
            font-size: 3rem;
        }

        &__priceless {
            color: #8B98B1;
        }
    }
</style>
