<script setup>
import { usePage } from '@inertiajs/vue3'

const seoData = usePage().props.seo_data
</script>

<template>
    <section class="seo-text seo-text--left">
        <div v-html="seoData.bottom_text"></div>
    </section>
</template>

<style lang="scss" scoped></style>
