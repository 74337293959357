<script setup>
  import { ref, computed, onMounted} from 'vue'
  import WhyChoose from '@/Components/General/WhyChoose.vue'
  import HowWork from '@/Components/General/HowWork.vue'
  import HowBuy from '@/Components/General/HowBuy.vue'
  import ServiceItem from '@/Components/Orders/ServiceItem.vue'
  import AppLayout from "@/Layout/AppLayout.vue";
  import { getPrice } from '@/Utils/bulkordersUtils.js';
  import { useForm, usePage } from '@inertiajs/vue3'
  import PayModal from "@/Components/Modals/PayModal.vue"
  import {trans} from "@/Utils/translation.utils.js";

  const props = defineProps({
      serviceList: [Array, Object],
      paymentSystemList: [Array, Object]
  })
  const seoData = usePage().props.seo_data

  const rows = ref([]);
  const isEmpty = computed(() => rows.value.length === 0);
  const loading = ref(false);
  const paymentLoading = ref(false);
  const formPaymentErrors = ref({});
  const bootstrap =  ref();
  const modalPayment =  ref();
  const form = useForm({
      rows: [],
      'customerEmail': null,
      'payment_system': null,
      'type':'bulk'
  });

  function createEmptyRow(serviceId = '') {
      const service = props.serviceList.find(srv => srv.id === serviceId);

      return {
          selectedService: serviceId,
          selectedQuantity: '',
          fields: service
              ? service.fields.reduce((acc, field) => {
                  acc[field.id] = '';
                  return acc;
              }, {})
              : {}
      };
  }

  function addRow(serviceId = '') {
      rows.value.push(createEmptyRow(serviceId));
  }

  function copyRow(index) {
      //const copiedRow = { ...rows.value[index] };
      const copiedRow = JSON.parse(JSON.stringify(rows.value[index]));
      rows.value.push(copiedRow);
  }

  function removeRow(index) {
      if (rows.value.length > 1) {
          rows.value.splice(index, 1);

          const updatedErrors = {};
          for (const key in form.errors) {
              if (key.startsWith(`rows.${index}`)) {
                  continue;
              } else if (key.startsWith("rows.")) {
                  const match = key.match(/^rows\.(\d+)\.(.*)$/);
                  if (match) {
                      const rowIndex = parseInt(match[1]);
                      const errorField = match[2];
                      if (rowIndex > index) {
                          updatedErrors[`rows.${rowIndex - 1}.${errorField}`] = form.errors[key];
                      } else {
                          updatedErrors[key] = form.errors[key];
                      }
                  }
              } else {
                  updatedErrors[key] = form.errors[key];
              }
          }
          form.errors = updatedErrors;
      }
  }

  const totalPrice = computed(() => {
      return rows.value.reduce((acc, row) => {
          return acc + getPrice(props.serviceList, row.selectedService, row.selectedQuantity);
      }, 0).toFixed(2);
  });

  const hasSelectedService = computed(() => rows.value.some(item => item.selectedService !== ""));

  function handleSubmit() {
      form.rows = rows.value;
      loading.value = true;

      form.post(route('payment.validate_customer_data'), {
          onSuccess: () => {
              openModalPayment()
          },
          onError: (errors) => {
              for (const [key, value] of Object.entries(errors)) {
                  form.errors[key] = value;
              }
          },
          onFinish: () => {
              loading.value = false;
          },
      });
  }

  function handlePaymentSubmit(selectedPayment) {
      paymentLoading.value = true;
      formPaymentErrors.value = {};
      form.payment_system = selectedPayment;

      form.post(route('payment'), {
          preserveState: true,
          onSuccess: () => {
          },
          onError: (errors) => {
              formPaymentErrors.value = errors;
              paymentLoading.value = false;
          },
          onFinish: () => {

          },
      });
  }
  const openModalPayment = () => {
      if (modalPayment) {
          modalPayment.value.show();
      }
  };

  onMounted(async () => {
      bootstrap.value = await import('bootstrap');
      modalPayment.value = new bootstrap.value.Modal(document.getElementById('payModal'), { keyboard: false });
  });

</script>

<template>
  <AppLayout>
      <section class="bulk-orders">
          <div class="container pb-5">
              <h1 class="page-title mb-4">{{seoData.h1_title}}</h1>
              <div class="bulk-orders-card c-card c-card--no-overflow p-4 p-lg-5">
                  <div class="mb-3">
                      <div v-if="isEmpty" class="bulk-orders-empty">
                          <div class="d-flex align-items-center">
                              <i class="me-2 bi bi-exclamation-triangle-fill"></i>
                              <span>{{trans('front.bulk.products_empty')}}</span>
                          </div>
                      </div>
                      <template v-else>
                          <ServiceItem
                              :rows="rows"
                              :service-list="props.serviceList"
                              :form="form"
                              @row-copied="copyRow"
                              @row-removed="removeRow"
                          />
                      </template>
                  </div>

                  <div class="row g-4">
                      <div class="col-12 col-lg-5">
                          <button @click="addRow()" class="bulk-orders-card__add-service btn btn-secondary">
                              {{ trans('front.bulk.add_service') }}
                          </button>
                      </div>
                      <div class="col-12 col-lg-7">
                          <div class="d-flex flex-column align-items-center align-items-lg-end">
                              <div class="bulk-orders-card__price">${{ totalPrice }}</div>
                              <div class="bulk-orders-card__footer d-flex flex-column flex-lg-row align-items-center w-100">
                                  <div class="flex-grow-1 mb-3 mb-lg-0 me-lg-4">
                                      <input
                                          type="email"
                                          class="form-control form-control-lg text-center"
                                          :placeholder="trans('front.input_placeholder_email')"
                                          v-model="form['customerEmail']"
                                          required
                                      />
                                      <div v-if="form.errors['customerEmail']" class="text-danger mt-1" style="font-size: 14px">
                                          {{ form.errors['customerEmail'] }}
                                      </div>
                                  </div>

                                  <button @click.prevent="handleSubmit()" class="bulk-orders-card__order-btn btn btn-lg btn-success"
                                          :disabled="loading || !hasSelectedService" style="min-width: 240px;">
                                      <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      <span v-if="!loading">{{ trans('front.get-it-now') }}</span>
                                      <span v-if="loading">Processing...</span>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


          <PayModal
              :paymentSystemList="props.paymentSystemList.data"
              :loading="paymentLoading"
              :formErrors="formPaymentErrors"
              @submitForm="handlePaymentSubmit"
          />

          <WhyChoose />
          <HowWork />
          <HowBuy />
      </section>
  </AppLayout>
</template>

<style lang="scss" scoped>
.bulk-orders-card {
    &__price {
        color: #141e33;
        font-size: 3rem;
        font-weight: 400;
    }

    &__add-service {
        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__footer {
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    &__order-btn {
        &:disabled {
            color: white;
            opacity: 0.65;
        }
    }
}

.bulk-orders-empty {
    padding: 1rem;
    border: 0.0625rem solid #b6d4fe;
    border-radius: 0.5rem;
    background-color: #cfe2ff;
    color: #084298;
}



.c-card {
    overflow-x: visible !important;
}

</style>
