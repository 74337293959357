<script setup>
    import { Link , usePage } from '@inertiajs/vue3'
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'

    const props = defineProps({
        freeTools: Object,
    })

    const seoData = usePage().props.seo_data
</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{seoData.h1_title}}</h1>
                <div class="row g-4 justify-content-center">
                    <div class="col-12 col-lg-4" v-for="(item, index) in freeTools.data" :key="`tool_${index}`">
                        <div class="c-card h-100 py-4 px-3 text-center">
                            <img class="mx-auto mb-2" :src="item.icon" :alt="item.name" width="72" height="72"/>
                            <h5 class="free-tools-title fs-5 lh-sm">{{ item.name }}</h5>
                            <div class="d-flex flex-column">
                                <Link v-for="(freeTool, index) in item.free_tools" :key="`link_${index}`" :href="freeTool.link" :class="['fs-5 lh-sm', { 'mb-2': index !== item.free_tools.length - 1 }]">
                                    {{ freeTool.name }}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
    .free-tools-title {
        font-family: 'Ruda', 'Montserrat', sans-serif;
        font-weight: 800;
    }
</style>
