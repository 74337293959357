<script setup>
import {trans} from "@/Utils/translation.utils.js";
import { computed  } from 'vue'
import CAccordion from '@/Components/Ui/CAccordion.vue'
import { Head } from '@inertiajs/vue3'

const props = defineProps({
    faqs: Object,
    schema: Object
})

const isAllItemEmpty = computed(() => {
    return props.faqs.length === 0 || props.faqs.every(
        item => item.question === "" && item.answer === ""
    );
})

</script>

<template>
  <div class="faq c-card p-4 p-lg-5" v-if="!isAllItemEmpty">
    <h2 class="faq__title"> {{trans('front.faq-title-on-entity')}}</h2>
    <div class="faq__content">
      <CAccordion :items="faqs" :collapsed="false"/>
    </div>
  </div>
    <Head>
        <component v-if="schema.mainEntity.length && !isAllItemEmpty" is="script" type="application/ld+json" head-key="faqPageSchema">
            {{ JSON.stringify(schema) }}
        </component>
    </Head>

</template>

<style lang="scss" scoped>
  .faq {
    &__title {
      margin-bottom: 1rem;
      text-align: center;
    }
  }
</style>
