<script setup>
    import AppLayout from "@/Layout/AppLayout.vue";
    import { ref, onMounted,computed  } from 'vue'
    import { useForm } from '@inertiajs/vue3'
    import PayModal from "@/Components/Modals/PayModal.vue";
    import {trans} from "@/Utils/translation.utils.js";

    const props = defineProps({
        isFail: Boolean,
        invoice: {
            type: Object,
            default: null
        }
    })

    const paymentLoading = ref(false);
    const formPaymentErrors = ref({});
    const bootstrap =  ref();

    const formPaymentData = useForm({
        'invoice_uuid': computed(() => props.invoice ? props.invoice.data.uuid : null),
        'payment_system': null,
        'type': 'invoice'
    })

    function handlePaymentSubmit(selectedPayment) {
        paymentLoading.value = true;
        formPaymentErrors.value = {};
        formPaymentData.payment_system = selectedPayment;

        formPaymentData.post(route('payment'), {
            preserveState: true,
            onSuccess: () => {
            },
            onError: (errors) => {
                formPaymentErrors.value = errors;
                paymentLoading.value = false;
            },
            onFinish: () => {
            },
        });
    }

    const openModalPayment = () => {
        if (typeof window !== 'undefined' && bootstrap.value) {
            let modalPayment = new bootstrap.value.Modal(document.getElementById('payModal'), {
                keyboard: false
            })

            modalPayment.show();
        }
    };

    const getClassByStatus = (status) => {
        if (typeof status !== 'string') {
            return null;
        }

        const classes = {
            'pending': 'text-info',
            'paid': 'text-success',
            'failed': 'text-danger',
            'refunded': 'text-warning',
        };

        return classes[status] || null;
    };

    const formatPrice = (num) => {
        return num ? Number(num).toFixed(2) : 0.00;
    };

    onMounted(async () => {
        bootstrap.value = await import('bootstrap');
    });

</script>

<template>
  <AppLayout>
      <section class="invoice">
          <div class="container">
              <div v-if="isFail" class="text-danger">
                  {{trans('front_invoice.error')}}
              </div>
              <div v-else class="invoice-card c-card">
                  <h2 class="invoice-card__title mb-3">{{trans('front_invoice.invoice_number')}}{{invoice.data.uuid_for_payment_system}}</h2>
                  <div class="d-flex align-items-center mb-3">
                      <strong class="me-2">Email:</strong>
                      <span>{{invoice.data.email}}</span>
                  </div>
                  <div class="d-flex align-items-center mb-4">
                      <strong class="me-2">{{trans('front_invoice.date')}}:</strong>
                      <span>{{invoice.data.created_at}}</span>
                  </div>

                  <div class="invoice-card-item mb-3">
                      <div v-for="(detail, detailIndex) in invoice.data.details" :key="detailIndex" class="row mt-0">
                        <div class="col-12 col-lg-2 position-count">
                              <strong class="mb-2">{{ trans('front_invoice.count') }}</strong>
                          </div>
                          <div class="col-12 col-lg-6 position-service">
                              <strong class="mb-2">{{ trans('front_invoice.service') }}</strong>
                          </div>
                          <div class="col-12 col-lg-2 position-price">
                              <strong class="mb-2">{{ trans('front_invoice.summ') }}</strong>
                          </div>
                          <div class="col-12 col-lg-2 position-status">
                              <strong class="mb-2">{{ trans('front_invoice.status') }}</strong>
                          </div>


                          <div class="col-12 col-lg-2 mt-lg-3 mb-3 mb-lg-0 position-count">
                              <span>{{ detail.quantity }}</span>
                          </div>
                          <div class="col-12 col-lg-6 mt-lg-3 mb-3 mb-lg-0 position-service">
                                <span v-if="detail.purchasable.products && detail.purchasable.products.length">
                                   {{detail.purchasable.subscription_type.name}}  {{ trans('email.order_created.package_service_name') }}
                                    <u>
                                        <li v-for="(product, index) in detail.purchasable.products" :key="index">
                                            {{ product.quantity }} {{ product.name }}
                                        </li>
                                    </u>
                                </span>
                              <span v-else>
                                {{ detail.quantity }} {{ detail.purchasable.name }}
                              </span>
                          </div>
                          <div class="col-12 col-lg-2 mt-lg-3 mb-3 mb-lg-0 position-price">
                              <span>{{ formatPrice(detail.price) }}</span>
                          </div>
                          <div class="col-12 col-lg-2 mt-lg-3 mb-3 mb-lg-0 position-status" :class="getClassByStatus(invoice.data.status)">
                              <span>{{ trans(`front_invoice.status_${invoice.data.status}`) }}</span>
                          </div>
                      </div>
                  </div>

                  <div class="row g-4">
                      <div class="col-12 col-lg-9 order-1 order-lg-0">
                          <div class="d-flex justify-content-center justify-content-lg-start align-items-center mb-3">
                              <img class="me-2" src="/img/icons/paypal.svg" alt="paypal" width="38" />
                              <span class="invoice__payment-text">{{trans('front_payment.invoice_payment_text')}}</span>
                          </div>
                          <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/mastercard.svg" alt="Mastercard" width="50" />
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/visa.svg" alt="Visa" width="50" />
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/amex.svg" alt="Amex" width="50" />
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/discover.svg" alt="Discover" width="50" />
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/applepay.svg" alt="Applepay" width="50" />
                              <img class="m-1 m-md-0 me-md-2" src="/img/icons/googlepay.svg" alt="Google pay" width="50" />
                              <img class="m-1 m-md-0" src="/img/icons/bitcoin.svg" alt="Bitcoin" width="50" />
                          </div>
                      </div>
                      <div class="col-12 col-lg-3 order-0 order-lg-1">
                          <div class="d-flex flex-column align-items-end">
                              <span class="mb-2" style="font-size: 28px">${{invoice.data.total_amount}}</span>
                              <button v-if="invoice.data.enablePayment && invoice.data.paymentSystemList.length"
                                      :disabled="paymentLoading"
                                      class="invoice__payment-btn btn btn-lg btn-success"
                                      @click="openModalPayment">
                                  {{trans('front_payment.invoice_button_payment')}}
                              </button>
                          </div>
                      </div>
                  </div>
                  <PayModal
                      :paymentSystemList="invoice.data.paymentSystemList"
                      :loading="paymentLoading"
                      :formErrors="formPaymentErrors"
                      @submitForm="handlePaymentSubmit"
                  />

              </div>
          </div>
      </section>

  </AppLayout>
</template>

<style lang="scss" scoped>
  .invoice {
    padding: 1.125rem 0 3rem;
  }
  .invoice-card {
    padding: 3rem;

    @media (max-width: 767px) {
      padding: 1.5rem;
    }
  }
</style>
