<script setup>
import {Head, usePage} from '@inertiajs/vue3'
import MainHeader from "@/Components/General/MainHeader.vue";
import MainFooter from "@/Components/General/MainFooter.vue";
import CBreadcrumbs from '@/Components/Ui/CBreadcrumbs.vue'
import ZendeskChat from '@/Components/Partials/ZendeskChat.vue'

const seoData = usePage().props.seo_data || {}
const organizationSchema = usePage().props.organizationSchema
const breadcrumbList = usePage().props.breadcrumbs
const canonicalUrlList = usePage().props.canonicalUrlList || []
const homeUrl = usePage().props.homeUrl

const canonicalHref = (() => {
    if (!Array.isArray(canonicalUrlList)) {
        return null;
    }
    const canonicalLink = canonicalUrlList.find(link => link.rel === 'canonical');
    return canonicalLink ? canonicalLink.href : null;
})();

const fullImageLogoUrl = () => {
    return `${homeUrl}img/logo.png`;
};
</script>

<template>
    <Head>
        <title>{{ seoData.meta_title || '' }}</title>
        <meta head-key="description" name="description" :content="seoData.meta_description || ''">
        <meta head-key="keywords" name="keywords" :content="seoData.meta_keywords || ''">
        <meta head-key="og:site_name" property="og:site_name" content="Viplikes">
        <meta head-key="og:title" property="og:title" :content="seoData.meta_title || ''">
        <meta head-key="og:description" property="og:description" :content="seoData.meta_description || ''">
        <meta head-key="og:url" property="og:url" :content="canonicalHref">
        <meta head-key="og:type" property="og:type" content="activity">
        <meta head-key="og:image" property="og:image" :content="fullImageLogoUrl()">
        <meta head-key="og:image:secure_url" property="og:image:secure_url" :content="fullImageLogoUrl()">
        <meta head-key="twitter:card" name="twitter:card" content="summary">
        <meta head-key="twitter:site" name="twitter:site" :content="canonicalHref">
        <meta head-key="twitter:image" name="twitter:image" :content="fullImageLogoUrl()">
        <meta head-key="twitter:title" name="twitter:title" :content="seoData.meta_title || ''">
        <meta head-key="twitter:description" name="twitter:description" :content="seoData.meta_description || ''">
        <meta head-key="twitter:image" name="twitter:image" :content="fullImageLogoUrl()">
        <meta head-key="image" itemprop="image" :content="fullImageLogoUrl()">
        <template v-for="(link,index) in canonicalUrlList" :key="index">
            <link v-if="link.rel === 'canonical'" :rel="link.rel" :href="link.href"
                  :head-key="`canonical-link-${index}`">
            <link v-else :rel="link.rel" :hreflang="link.hreflang" :href="link.href"
                  :head-key="`canonical-link-${index}`">
        </template>
        <component is="script" type="application/ld+json" v-if="organizationSchema && organizationSchema.length !== 0"
                   head-key="organizationSchema">
            {{ JSON.stringify(organizationSchema) }}
        </component>
        <component is="script">
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-T7JS2H6');
        </component>
    </Head>
    <MainHeader/>
    <main class="main-content">
        <div class="py-2">
            <CBreadcrumbs :breadcrumbs="breadcrumbList" v-if="breadcrumbList.length > 1"/>
        </div>
        <slot/>
    </main>
    <MainFooter/>
    <ZendeskChat/>

</template>

<style scoped lang="scss">

</style>
