<script setup>
import {ref, onMounted, onUnmounted} from 'vue';

const lazyLoadRef = ref(null);
const isVisible = ref(false);

let observer;

if (typeof window !== 'undefined') {
  observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      isVisible.value = true;
      observer.disconnect();
    }
  });
}

onMounted(() => {
  if (lazyLoadRef.value && observer) {
    observer.observe(lazyLoadRef.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div ref="lazyLoadRef">
    <slot v-if="isVisible" />
  </div>
</template>
