<script>
import { ref, onMounted } from 'vue';
import {trans} from "@/Utils/translation.utils.js";

export default {
  methods: {trans},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const showNotification = ref(false);
        const currentNotification = ref({});
        const currentIndex = ref(0);

        const getRandomMinutes = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        // const reversedPrices = [...props.product.data.prices].reverse();
      const createFakeData = () => {
        const countries = props.product.data.countries;
        const defaultIcon = "/img/icons/facebook_likes.png";

        if (props.product.data.prices && props.product.data.prices.length > 0) {
          const socialMediaSlug = props.product.data.social_media.slug;
          const socialMediaIcons = ["facebook", "instagram", "linkedin", "tiktok", "twitter", "youtube"];

          const customIcon = socialMediaIcons.includes(socialMediaSlug)
              ? `/img/icons/icon-discount-${socialMediaSlug}.svg`
              : defaultIcon;

          const reversedPrices = [...props.product.data.prices].reverse();

          return reversedPrices.map((price, index) => ({
            item: `${price.quantity} ${props.product.data.name}`,
            country: countries[index % countries.length],
            minutes: getRandomMinutes(5, 20),
            icon: customIcon
          }));
        } else {
          let fakeData = [];

          const subscriptionTypes = Object.keys(props.product.data.packages_by_subscription_type);

          subscriptionTypes.forEach(subscriptionType => {
            const selectedPackages = props.product.data.packages_by_subscription_type[subscriptionType] || [];
            const reversedPackages = [...selectedPackages].reverse();

            reversedPackages.forEach((pkg, index) => {
              fakeData.push({
                item: pkg.titles.join(" / "),
                country: countries[index % countries.length],
                minutes: getRandomMinutes(5, 20),
                icon: defaultIcon
              });
            });
          });
          return fakeData;
        }
      };

      const fakeData = createFakeData();

      const displayNotification = () => {
        if (currentIndex.value < fakeData.length) {
          currentNotification.value = fakeData[currentIndex.value];
          showNotification.value = true;

          setTimeout(() => {
            showNotification.value = false;

            setTimeout(() => {
              currentIndex.value++;
              if (currentIndex.value < fakeData.length) {
                displayNotification();
              }
            }, 10000);
          }, 5000);
        }
      };

        onMounted(() => {
            setTimeout(() => {
                displayNotification();
            }, 10000);
        });

        return {
            showNotification,
            currentNotification
        };
    }
};
</script>

<template>
    <div class="last-buing active" v-if="showNotification">
        <img :src="currentNotification.icon" alt="" />
        <div class="last-buying__info">
            <div class="last-buing__title">{{ trans('front.notification_someone_from') }} {{ currentNotification.country }} {{ trans('front.notification_purchased') }}</div>
            <div class="last-buing__count">{{ currentNotification.item }}</div>
            <div class="last-buing__time">{{ currentNotification.minutes }} {{ trans('front.notification_minutes_ago') }}</div>
        </div>
    </div>
</template>

<style scoped>
</style>
