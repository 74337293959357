<script setup>
  import CSelect from '@/Components/Ui/CSelect.vue'
  import { getQuantityList, getPrice } from '@/Utils/bulkordersUtils.js';
  import {trans} from "@/Utils/translation.utils.js";

  const props = defineProps({
      serviceList: {
          type: Array,
          default: () => []
      },
      rows: {
          type: Array,
          required: true
      },
      form: {
          type: Object,
          default: () => []
      }
  })

  const emit = defineEmits(['row-removed', 'row-copied']);

  function removeRow(index) {
      emit('row-removed', index);
  }

  function copyRow(index) {
      emit('row-copied', index);
  }

  function getFieldsList(service) {
      const selectedOption = props.serviceList.find(srv =>
          srv.options.some(option => option.value === service)
      );

      if (selectedOption) {
          const option = selectedOption.options.find(opt => opt.value === service);
          return option?.fields || [];
      }
      return [];
  }

  function updateQuantity(index) {
      const row = props.rows[index];

      if (row.selectedService) {
          const service = props.serviceList.find(srv =>
              srv.options.some(option => option.value === row.selectedService)
          );
          const quantities = getQuantityList(props.serviceList, row.selectedService);

          if (service) {
              const option = service.options.find(opt => opt.value === row.selectedService);
              row.fields = option?.fields.reduce((acc, field) => {
                  acc[field.id] = '';
                  return acc;
              }, {});
          } else {
              row.fields = {};
          }

          if (quantities.length > 0) {
              row.selectedQuantity = quantities[0].value;
          }
      } else {
          row.selectedQuantity = '';
      }
  }

</script>

<template>
    <div class="">
        <div v-for="(row, index) in rows" :key="index" class="service-item mb-2">
            <div class="row g-4">
            <div class="col-12 col-lg-4">
                <strong class="text-dark">{{trans('front.bulk.service')}}</strong>
                <CSelect v-model="row.selectedService"
                         @change="updateQuantity(index)"
                         :groups="true"
                         :options="props.serviceList"
                         :placeholder="trans('front.bulk.select_service')"/>

                <span v-if="form.errors['rows.' + index + '.selectedService']" class="text-danger" style="font-size: 14px">
                    {{ form.errors['rows.' + index + '.selectedService'] }}
                </span>
            </div>

            <div class="col-12 col-lg-2">
                <strong class="text-dark">{{trans('front.bulk.quantity')}}</strong>
                <CSelect
                    v-model="row.selectedQuantity" :options="getQuantityList(props.serviceList, row.selectedService)" placeholder="0" :disabled="!row.selectedService" />
                <span v-if="form.errors['rows.' + index + '.selectedQuantity']" class="text-danger" style="font-size: 14px">
                    {{ form.errors['rows.' + index + '.selectedQuantity'] }}
                </span>
            </div>

            <div class="col-12 col-lg-4">
                <div v-for="item in getFieldsList(row.selectedService)" :key="item.id" class="mb-3">
                    <strong class="text-dark">{{ item.placeholder }}</strong>
                    <input
                        v-model="row.fields[item.id]"
                        :type="item.type || 'text'"
                        class="form-control form-control-lg"
                        :placeholder="item.placeholder"
                        :required="item.required || false"
                    />
                    <span v-if="form.errors['rows.' + index + '.fields.' + item.id]" class="text-danger" style="font-size: 14px">
                      {{ form.errors['rows.' + index + '.fields.' + item.id] }}
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-1">
                <div class="d-flex flex-column">
                    <strong class="mb-2">{{trans('front.bulk.price')}}</strong>
                    <span class="service-item__price">{{ getPrice(props.serviceList, row.selectedService, row.selectedQuantity) }}</span>
                </div>
            </div>

            <div class="col-12 col-lg-1">
                <div class="d-flex flex-column">
                    <strong class="mb-2 text-dark">{{trans('front.bulk.action')}}</strong>
                    <div class="d-flex align-items-center">
                        <a class="me-2" href="#" @click.prevent="removeRow(index)">
                            <i class="bi bi-x-circle text-danger fs-5"></i>
                        </a>
                        <a href="#" @click.prevent="copyRow(index)">
                            <i class="bi bi-copy text-blue-200 fs-5"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
  .service-item {
    padding: 1rem;
    border: 0.0625rem solid #c8d4e0;
    border-radius: 0.5rem;

    &__price {
      font-size: 1.25rem;
    }
  }
</style>
