<script>
import { onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

export default {
    setup() {
        const pageProps = usePage().props;
        const enableChat = ref(pageProps.enableChat);

        const loadZendeskScript = () => {
            const existingScript = document.querySelector('#ze-snippet');
            if (!existingScript) {
                const script = document.createElement('script');
                script.id = 'ze-snippet';
                script.async = true;
                script.src = 'https://static.zdassets.com/ekr/snippet.js?key=fcc24253-2142-4560-9f5c-1a400a461fc9';
                document.body.appendChild(script);
            } else {
                reloadChat();
            }
        };

        const hideChat = () => {
            if (window.zE) {
                window.zE('webWidget', 'hide');
            }
        };

        const reloadChat = () => {
            if (window.zE) {
                window.zE('webWidget', 'show');
            }
        };

        onMounted(() => {
            if (enableChat.value) {
                setTimeout(loadZendeskScript, 15000);
            } else {
                hideChat();
            }
        });

        return {
            enableChat,
        };
    },
};
</script>

<template>
    <div>
    </div>
</template>
