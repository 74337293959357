<script setup>
import { ref, onMounted} from 'vue'
import {trans} from "@/Utils/translation.utils.js";

const props = defineProps({
    paymentSystemList: Array,
    loading: Boolean,
    formErrors: Object,
    itemId: {
        type:Number,
        default:null
    },
});

const selectedPayment = ref('');

const handleSelectPayment = (value) => {
    selectedPayment.value = value;
};

const emit = defineEmits(['submitForm']);

function handleSubmit() {
    if (!selectedPayment.value) {
        return;
    }
    emit('submitForm', selectedPayment.value, props.itemId);
}

onMounted(async () => {
    if (props.paymentSystemList.length > 0) {
        selectedPayment.value = props.paymentSystemList[0].payment_method;
    }
});

</script>

<template>
    <div class="modal fade" id="payModal" tabindex="-1" aria-labelledby="payModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <img class="me-3" src="/img/payment-system.png" alt="Coupon" width="48" />
                    <p class="modal-title" id="payModalLabel">{{trans('front_payment.payment_modal_title')}}</p>
                    <button class="btn ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/img/icons/close-white.svg" alt="Close icon" width="16" height="16" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row g-4">
                        <div v-for="item in paymentSystemList" :key="item.title" class="col-12 col-md-6">
                            <div :class="['pay-item mb-2', { 'pay-item--active': selectedPayment === item.payment_method }]" @click="handleSelectPayment(item.payment_method)">
                                <div class="d-flex align-items-center">
                                    <div class="pay-item__radio me-3">
                                        <i class="bi bi-check text-white"></i>
                                    </div>
                                    <div class="pay-item__icon">
                                        <img :src="item.icon" :alt="item.name" width="140" height="44" />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="d-inline-flex align-items-center pay-text" v-html="item.description"/>
                            </div>
                        </div>
                        <div v-if="formErrors" class="text-center text-danger m-2">
                            <div v-for="(error,index) in formErrors" :key="index" class="col-12 col-md-6">
                                {{error}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-lg btn-success" :disabled="loading || !selectedPayment" @click="handleSubmit">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="!loading"> {{trans('front_payment.payment_modal_button_checkout')}}</span>
                        <span v-if="loading"> Processing...</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
  .modal-dialog {
    width: 100%;
    max-width: 50rem;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    border: none;
    color: #fff;
    background-color: #2d4472;
    font-family: 'Ruda', 'Montserrat', sans-serif;

    .btn-close {
      color: #fff;
    }
  }

  .modal-title {
    font-size: 1.25rem;
    font-weight: 800;
  }

  .modal-body {
    padding: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .modal-footer {
    padding: 1.5rem;
    padding-top: 0.5rem;
    border: none;
    justify-content: center;
  }

  .pay-item {
    min-height: 5rem;
    padding: 1rem;
    border: 0.0625rem solid #c8d4e0;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: box-shadow 0.25s ease;

    &:hover {
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
    }

    &__radio {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: 0.0625rem solid #c8d4e0;
      border-radius: 50%;
      font-size: 1.625rem;
    }

    &--active {
      border-color: #1e73be;

      .pay-item__radio {
        background-color: #76cd68;
        border-color: #76cd68;

        .bi {
          line-height: 1;
        }
      }
    }
  }

  .pay-text {
    color: #a1afcc;
    font-size: 0.75rem;

    &--danger {
      color: #ff4a4a;
    }
  }
</style>
