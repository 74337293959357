<script setup>
    import AppLayout from '@/Layout/AppLayout.vue'
    import WhyChoose from '@/Components/General/WhyChoose.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";
    import {Head, usePage } from '@inertiajs/vue3'
    import CAccordion from '@/Components/Ui/CAccordion.vue'

    const props = defineProps({
        faqGroups: Object,
    })
    const seoData = usePage().props.seo_data

</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{seoData.h1_title}}</h1>
                <div class="c-card p-4 p-lg-5">
                    <template v-for="(item, index) in  faqGroups.data" :key="index">
                        <h2 class="text-center pt-2 pb-3 m-0 lh-sm">{{item.name}} ({{item.faqs.length}} {{trans('front.main-faq-label-questions')}})</h2>
                        <CAccordion class="pb-4" :items="item.faqs"  :id="'accordion_' + index" />
                    </template>
                </div>
            </div>

            <WhyChoose />
            <HowWork />
            <HowBuy />
            <Head>
                <component is="script" type="application/ld+json"  head-key="faqSchema">
                    {{ JSON.stringify(faqGroups.schema) }}
                </component>
            </Head>
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
