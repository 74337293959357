<script setup>
import { usePage } from '@inertiajs/vue3'
import { ref, onMounted } from 'vue'

const seoData = usePage().props.seo_data
const processedBottomText = ref(seoData.bottom_text ? seoData.bottom_text.replace(/<img[^>]*src/g, '<img data-src') : '')

const isSsr = ref(true);

onMounted(() => {
    isSsr.value = false;
});
</script>

<template>
    <section class="seo-text seo-text--left" v-if="isSsr">
        <div v-html="processedBottomText"></div>
    </section>

    <section class="seo-text seo-text--left" v-lazy-container="{ selector: 'img' }" v-if="!isSsr">
        <div v-html="processedBottomText"></div>
    </section>
</template>

<style lang="scss" scoped></style>
