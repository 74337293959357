<script setup>
    import { ref } from 'vue'
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";
    import { usePage, useForm } from '@inertiajs/vue3'
    import AlertComponent from '@/Components/Partials/Alert.vue'
    import ReCaptcha from '@/Components/Partials/ReCaptcha.vue';

    const seoData = usePage().props.seo_data

    const form = useForm({
        subscription_id: '',
        cancelation_reason: '',
        email: '',
        recaptchaToken: ''
    });

    const formErrors = ref({});
    const validationForm = ref()
    const isSuccess = ref(false)

    const handleToken = (token) => {
        formErrors.value.recaptchaToken = '';
        form.recaptchaToken = token;
    };
    const handleExpired = () => {
        formErrors.value.recaptchaToken = trans('front_payment.validation.recaptcha_expired');
    };

    const handleSubmit = () => {
        formErrors.value = {};

        form.post(route('unsubscribe.send'), {
            preserveState: true,
            onSuccess: () => {
                form.reset();
                isSuccess.value = true
            },
            onError: (errors) => {
                formErrors.value = errors;
            }
        });
    };
</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{seoData.h1_title}}</h1>
                <div class="pb-4 text-center lh-sm">
                    <div v-html="seoData.top_text"></div>
                </div>

                <div class="c-card p-4 p-md-5" v-if="!isSuccess">
                    <div class="row g-0 justify-content-center">
                        <div v-if="!isSuccess" class="col-12 col-lg-6">
                            <form @submit.prevent="handleSubmit" ref="validationForm" class="pb-4" novalidate>
                                <label for="subscription_id" class="form-label fw-bold lg-sm text-dark">{{ trans('front_unsubscribe_form.subscripton_id_label') }}</label>
                                <div class="input-group has-validation mb-3">
                                    <input
                                        v-model="form.subscription_id"
                                        id="subscription_id"
                                        type="text"
                                        class="form-control form-control-lg"
                                        :placeholder="trans('front_unsubscribe_form.subscripton_id_placeholder')"
                                        :class="{
                                                'is-invalid': formErrors.subscription_id,
                                                'is-valid': !formErrors.subscription_id && form.subscription_id
                                            }"
                                        required
                                    />
                                    <div v-if="formErrors.subscription_id" class="invalid-feedback">
                                        {{ formErrors.subscription_id }}
                                    </div>
                                </div>

                                <label for="cancelation_reason" class="form-label fw-bold lg-sm text-dark">{{ trans('front_unsubscribe_form.reason_cancelation_label') }}</label>
                                <div class="input-group has-validation mb-3">
                                    <input
                                        v-model="form.cancelation_reason"
                                        id="cancelation_reason"
                                        type="text"
                                        class="form-control form-control-lg"
                                        :placeholder="trans('front_unsubscribe_form.reason_cancelation_placeholder')"
                                        :class="{
                                                'is-invalid': formErrors.cancelation_reason,
                                                'is-valid': !formErrors.cancelation_reason && form.cancelation_reason
                                            }"
                                    />

                                    <div v-if="formErrors.cancelation_reason" class="invalid-feedback">
                                        {{ formErrors.cancelation_reason }}
                                    </div>
                                </div>

                                <label for="email_field" class="form-label fw-bold lg-sm text-dark">{{ trans('front_unsubscribe_form.email_label') }}</label>
                                <div class="input-group has-validation mb-3">
                                    <input
                                        v-model="form.email"
                                        id="email_field"
                                        type="email"
                                        class="form-control form-control-lg"
                                        :placeholder="trans('front_unsubscribe_form.email_placeholder')"
                                        :class="{
                                                'is-invalid': formErrors.email,
                                                'is-valid': !formErrors.email && form.email
                                            }"
                                    />
                                    <div v-if="formErrors.email" class="invalid-feedback">
                                        {{ formErrors.email }}
                                    </div>
                                </div>

                                <div class="row g-0 justify-content-center has-validation mb-3">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <ReCaptcha @tokenVerified="handleToken" @tokenExpired="handleExpired" :error="formErrors.recaptchaToken"/>
                                    </div>
                                </div>

                                <div class="row g-0 justify-content-center">
                                    <div class="col-12 col-md-6 col-lg-8">
                                        <button class="w-100 btn btn-lg btn-success" type="submit">
                                            {{ trans('front_unsubscribe_form.button_text') }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-else class="c-card c-card--no-overflow p-4 p-md-5">
                    <div class="row g-0 justify-content-center">
                        <div class="col-12 col-lg-6">
                            <AlertComponent type="success" :message="trans('front_unsubscribe_form.message_success')" />
                        </div>
                    </div>
                </div>

                <div class="pt-4 text-center lh-sm">
                    <div v-html="seoData.bottom_text"></div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
