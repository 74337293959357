<script setup>
import {trans} from "@/Utils/translation.utils.js";

const items = [
    {
        iconUrl: '/img/icons/why-choose-1.svg',
        title: trans('front.why-chose-item-title-1'),
        content: trans('front.why-chose-item-content-1')
    },
    {
        iconUrl: '/img/icons/why-choose-2.svg',
        title: trans('front.why-chose-item-title-2'),
        content: trans('front.why-chose-item-content-2')
    },
    {
        iconUrl: '/img/icons/why-choose-3.svg',
        title: trans('front.why-chose-item-title-3'),
        content: trans('front.why-chose-item-content-3')
    }
]
</script>

<template>
  <section class="why-choose">
    <div class="container">
      <h2 class="why-choose__title">{{trans('front.why-chose-h2')}}</h2>
      <div class="row justify-content-center">
        <div class="why-choose__subtitle col-12 col-lg-8">
            {{trans('front.why-chose-text')}}
        </div>
      </div>
      <div class="why-choose__content">
        <div class="row g-4 align-items-stretch">
          <div v-for="(item, index) in items" :key="`why-choose-${index}`" class="col-12 col-xl-4">
            <div class="why-choose-item h-100">
              <div class="why-choose-item__img">
                <img :src="item.iconUrl" :alt="item.title" width="130" height="130" loading="lazy"/>
              </div>
              <h3 class="why-choose-item__title">
                {{ item.title }}
              </h3>
              <div class="why-choose-item__content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .why-choose {
    padding: 3rem 0;
    border-top: 0.0625rem solid #c8d4e0;
    text-align: center;

    &__title {
      font-weight: 800;
      margin-bottom: 1.5rem;
    }

    &__subtitle {
      margin: 0 auto 1.5rem;
    }
  }

  .why-choose-item {
    padding: 1.5rem 1rem;
    border: 0.0625rem solid #c8d4e0;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);

    &__img {
      max-width: 8.125rem;
      margin: 0 auto 1rem;
    }

    &__title {
      font-weight: 800;
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }

    &__content {
      font-size: 0.75rem;
    }
  }
</style>
