<script setup>
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import { usePage } from '@inertiajs/vue3'

    const props = defineProps({
        page: Object,
    })

    const seoData = usePage().props.seo_data

</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{seoData.h1_title}}</h1>
                <div class="pb-4 text-center lh-sm">
                    <div class="m-2" v-html="seoData.top_text"></div>
                </div>

                <div class="c-card p-4 p-md-5">
                    <div class="row g-0 justify-content-center">
                        <div class="col-12">
                            <div v-html="page.data.content"></div>
                        </div>
                    </div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
