<script setup>
import {trans} from "@/Utils/translation.utils.js";
  const items = [
    {
      iconUrl: '/img/icons/provide-1.svg',
      title: trans('front.what-provide-item-title-1'),
      content: trans('front.what-provide-tem-content-1')
    },
    {
      iconUrl: '/img/icons/provide-2.svg',
        title: trans('front.what-provide-item-title-2'),
        content: trans('front.what-provide-tem-content-2')

    },
    {
      iconUrl: '/img/icons/provide-3.svg',
        title: trans('front.what-provide-item-title-3'),
        content: trans('front.what-provide-tem-content-3')

    },
    {
      iconUrl: '/img/icons/provide-4.svg',
        title: trans('front.what-provide-item-title-4'),
        content: trans('front.what-provide-tem-content-4')

    },
    {
      iconUrl: '/img/icons/provide-5.svg',
        title: trans('front.what-provide-item-title-5'),
        content: trans('front.what-provide-tem-content-5')

    }
  ]
</script>

<template>
  <section class="what-provide">
    <div class="container">
      <h2 class="what-provide__title">{{trans('front.what-provide-h2')}}</h2>
      <div class="what-provide__content">
        <div class="row g-4 justify-content-center">
          <div v-for="(item, index) in items" :key="`what-provide-${index}`" class="col-12 col-md-4 col-xl">
            <div :class="`what-provide-item what-provide-item--${index + 1}`">
              <div class="what-provide-item__img">
                <img :src="item.iconUrl" :alt="item.title" />
              </div>
              <h3 class="what-provide-item__title">
                {{ item.title }}
              </h3>
              <div class="what-provide-item__content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .what-provide {
    padding: 3rem 0;
    border-top: 0.0625rem solid #c8d4e0;
    text-align: center;

    &__title {
      font-weight: 800;
      margin-bottom: 1.5rem;
    }
  }

  .what-provide-item {
    &__img {
      max-width: 6rem;
      margin: 0 auto 1rem;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    &__content {
      font-size: 0.75rem;
    }
  }
</style>
