<script setup>
import { onMounted} from 'vue';

const loadTrustpilotScript = () => {
    if (!document.querySelector('script[src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.head.appendChild(script);
    } else if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(document.querySelector('.trustpilot-widget'));
    }
};

onMounted(() => {
    loadTrustpilotScript();
});

</script>

<template>
    <div
        class="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="5d639af5e3c39f00012d51a0"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
        data-style-alignment="center"
    >
        <a href="https://uk.trustpilot.com/review/viplikes.uk" target="_blank" rel="noopener">Trustpilot</a>
    </div>
</template>
