<script setup>
import {Link, Head} from '@inertiajs/vue3'
import { computed  } from 'vue'

const props = defineProps({
    breadcrumbs: Array
})

const isLastItem = (index) => {
    return index === props.breadcrumbs.length - 1
}
const generateJsonLd = () => {
    const items = props.breadcrumbs.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": item.title,
        "item": item.link || item.url
    }))

    return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": items
    }
}
const jsonLdData = computed(() => JSON.stringify(generateJsonLd()))

</script>

<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <template v-for="(item, index) in breadcrumbs">
        <li v-if="!isLastItem(index)" :key="item.title" class="breadcrumb-item">
          <Link :href="item.link || item.url">{{ item.title }}</Link>
        </li>
        <li v-else :key="`${item.title}_active`" class="breadcrumb-item active" aria-current="page">
          {{ item.title }}
        </li>
      </template>
    </ol>
  </nav>
    <Head>
        <component is="script" type="application/ld+json" v-if="jsonLdData" head-key="breadcrumbSchema">
            {{ jsonLdData }}
        </component>
    </Head>
</template>

<style scoped lang="scss">
  .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-family: 'Ruda', 'Montserrat', sans-serif;

    a {
      color: #ff7a7a;
    }
  }
</style>
