<script setup>
import {defineEmits, onMounted, ref} from 'vue';
import {RecaptchaV2} from "vue3-recaptcha-v2";
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
    error: {
        type: String,
        default: null
    }
});

const locale = usePage().props.currentLocal;
const emit = defineEmits(['tokenVerified', 'tokenExpired']);
const recaptchaLoaded = ref(false);

const handleToken = (token) => {
    emit('tokenVerified', token);
};

const handleExpired = () => {
    emit('tokenExpired');
};

onMounted(() => {
    if (typeof window !== 'undefined') {
        recaptchaLoaded.value = true;
    }
});
</script>

<template>
    <div>
        <RecaptchaV2
            v-if="recaptchaLoaded"
            :language="locale"
            @expired-callback="handleExpired"
            @load-callback="handleToken"
        />
        <div v-if="error" class="invalid-feedback_ text-danger mt-1" style="font-size: 14px">
            {{ error }}
        </div>
    </div>
</template>
