import {usePage} from "@inertiajs/vue3"

// export const trans = (key) => {
//     const translations = usePage().props.translations
//
//     return translations[key] ?? key
// }

export const trans = (key, replacements = {}) => {

    const translations = usePage().props.translations
    let translation = translations[key] ?? key;

    Object.keys(replacements).forEach((replaceKey) => {
        const replaceValue = replacements[replaceKey];
        translation = translation.replace(`:${replaceKey}`, replaceValue);
    });

    return translation;
};
