<script setup>
    import { ref } from 'vue'
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import DiscountCard from '@/Components/Discount/DiscountCard.vue'
    import { usePage, useForm, } from '@inertiajs/vue3'
    import {trans} from "@/Utils/translation.utils.js";

    const props = defineProps({
        items: [Object, Array],
        linkTerms: String,
        discount: Number
    })

    const seoData = usePage().props.seo_data
    const bestsellerList = props.items.data.filter(item => item.type === "bestsellers");
    const salesTrendsList = props.items.data.filter(item => item.type === "sales_trends");
    const recommendedServiceList = props.items.data.filter(item => item.type === "recommended_services");
    const paymentLoading = ref(false);
    const forms = ref({});

    props.items.data.forEach(item => {
        forms.value[item.id] = useForm({
            customerEmail: null,
            customerData: {},
            id: item.id,
            payment_system: null,
            type: 'discount',
        });
        item.entity.fields.forEach(field => {
            forms.value[item.id].customerData[field.id] = {
                value: '',
                type: field.type,
                required: field.required,
                placeholder: field.placeholder,
                name: field.name
            };
        });
    });

    function handlePaymentSubmit(selectedPayment, itemId) {
        paymentLoading.value = true;
        const form = forms.value[itemId];
        form.clearErrors();
        form.payment_system = selectedPayment;
        form.post(route('payment'), {
            preserveState: true,
            onSuccess: () => {
            },
            onError: (errors) => {
                paymentLoading.value = false;
            },
            onFinish: () => {
            },
        });
    }

</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm" v-html="seoData.h1_title"></h1>
                <div class="pb-5">
                    <h2 class="lh-sm text-center pt-2 pb-4 m-0">{{trans('front.bestsellers')}}</h2>
                    <div class="row g-4 align-items-stretch">
                        <div class="col-12 col-md-6 col-lg-3" v-for="(item, index) in bestsellerList" :key="`besteller-${index}`">
                            <DiscountCard  class="h-100" :item="item" :discount="discount"  :linkTerms="linkTerms" :form="forms[item.id]" :paymentLoading="paymentLoading" @paymentSubmit="handlePaymentSubmit" />
                        </div>
                    </div>
                </div>

                <div class="pb-5">
                    <h2 class="lh-sm text-center pt-2 pb-4 m-0">{{trans('front.discount.sales_trends')}}</h2>
                    <div class="row g-4 align-items-stretch">
                        <div class="col-12 col-md-6 col-lg-3" v-for="(item, index) in salesTrendsList" :key="`sales_trends-${index}`">
                            <DiscountCard  class="h-100" :item="item" :discount="discount" :linkTerms="linkTerms" :form="forms[item.id]" :paymentLoading="paymentLoading"  @paymentSubmit="handlePaymentSubmit" />
                        </div>
                    </div>
                </div>

                <div class="pb-5">
                    <h2 class="lh-sm text-center pt-2 pb-4 m-0">{{trans('front.discount.recommended_services')}}</h2>
                    <div class="row g-4 align-items-stretch">
                        <div class="col-12 col-md-6 col-lg-3" v-for="(item, index) in recommendedServiceList" :key="`recommended_services-${index}`">
                            <DiscountCard  class="h-100" :item="item" :discount="discount" :linkTerms="linkTerms" :form="forms[item.id]" :paymentLoading="paymentLoading"  @paymentSubmit="handlePaymentSubmit" />
                        </div>
                    </div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
