import '../scss/styles.scss';

import { createInertiaApp, router } from '@inertiajs/vue3';
import { createSSRApp, h } from 'vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { install } from "vue3-recaptcha-v2";
import VueLazyload from 'vue-lazyload';

import 'bootstrap-icons/font/bootstrap-icons.css';

if (typeof window !== 'undefined') {
    import('./bootstrap');
    import('bootstrap');
}

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: true })),
    setup({ el, App, props, plugin }) {
        return createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(install, {
                sitekey: props.initialPage.props.recaptchaSiteKey,
                cnDomains: false,
            })
            .use(VueLazyload)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

window.onload = function() {
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }
};

if (import.meta.env.VITE_APP_ENV === "production") {
    router.on('navigate', (event) => {
        gtag("js", new Date());
        gtag("config", "UA-168123280-1");
    });
}


