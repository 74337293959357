<script setup>
  import { ref, computed, onMounted, watch} from 'vue'
  import SeoTopText from "@/Components/General/SeoTopText.vue";
  import WhatProvide from '@/Components/General/WhatProvide.vue'
  import WhyChoose from '@/Components/General/WhyChoose.vue'
  import HowWork from '@/Components/General/HowWork.vue'
  import HowBuy from '@/Components/General/HowBuy.vue'
  import PayModal from '@/Components/Modals/PayModal.vue'
  import DiscountModal from '@/Components/Modals/DiscountModal.vue'
  import AppLayout from "@/Layout/AppLayout.vue";
  import {Link, usePage, Head, useForm } from '@inertiajs/vue3'
  import {trans} from "@/Utils/translation.utils.js";
  import FAQ from "@/Components/General/FAQ.vue";
  import Notification from '@/Components/General/Notification.vue';
  import SeoTextBuyService from "@/Components/General/SeoTextBuyService.vue";

  const props = defineProps({
      packageGroup: Object,
  })

  const seoData = usePage().props.seo_data
  const tabs = ref([]);
  const selectedTab = ref(null);
  const serviceList = computed(() => props.packageGroup.data.packages_by_subscription_type[selectedTab.value] || []);
  const selectedService = ref(null)
  const allServices = ref({});
  const loading = ref(false);
  const paymentLoading = ref(false);
  const validationForm = ref([]);
  const bootstrap =  ref();

  const handleSelectService = (item) => {
    serviceList.value.forEach((item) => {
      item.checked = false
    })
    item.checked = true

    selectedService.value = item
  }
  const updateTabs = () => {
      tabs.value = Object.keys(props.packageGroup.data.packages_by_subscription_type);
      validationForm.value =  Object.keys(props.packageGroup.data.packages_by_subscription_type);
  };
  const updateServiceList = () => {
      const currentTabServices = serviceList.value;
      if (currentTabServices.length > 0) {
          Object.values(allServices.value).flat().forEach(service => service.checked = false);
          currentTabServices.forEach(service => service.checked = false);
          currentTabServices[0].checked = true;
          selectedService.value = currentTabServices[0];
      }
  };
  const initialize = () => {
      updateTabs();
      if (tabs.value.length > 0) {
          selectedTab.value = tabs.value[0];
          updateServiceList();
      }
  };
  const isAllFeatureEmpty = computed(() => {
      return props.packageGroup.data.feature_descriptions.every(
          item => item.title === ""
      );
  })

  const formPaymentData = useForm({
      'customerEmail': null,
      'customerData': {},
      'packageId':  computed(() => selectedService.value ? selectedService.value.id : null),
      'payment_system': null,
      'type':'package'
  })

  props.packageGroup.data.input_fields.forEach(field => {
      formPaymentData['customerData'][field.id] = {
          value: '',
          type: field.type,
          required: field.required,
          placeholder: field.placeholder,
          name: field.name
      };

  });

  const openModalPayment = () => {
      if (typeof window !== 'undefined' && bootstrap.value) {
          let modalPayment = new bootstrap.value.Modal(document.getElementById('payModal'), {
              keyboard: false
          })
          modalPayment.show();
      }
  };

  function handleFormRef(formRef, index) {
      validationForm.value[index] = formRef;
  }
  function handleSubmit(tab) {
      loading.value = true;
      formPaymentData.clearErrors();
      const currentForm = validationForm.value[tab];

      formPaymentData.post(route('payment.validate_customer_data'), {
          preserveState: true,
          onSuccess: () => {
              openModalPayment()
              currentForm.classList.remove('was-validated');
          },
          onError: (errors) => {
              currentForm.classList.add('was-validated');
          },
          onFinish: () => {
              loading.value = false;
          },
      });
  }
  function handlePaymentSubmit(selectedPayment) {
      paymentLoading.value = true;
      formPaymentData.payment_system = selectedPayment;
      formPaymentData.clearErrors();

      formPaymentData.post(route('payment'), {
          preserveState: true,
          onSuccess: () => {
          },
          onError: (errors) => {
              paymentLoading.value = false;
          },
          onFinish: () => {

          },
      });
  }

  watch(selectedTab, () => {
      updateServiceList();
  });

  onMounted(async () => {
      bootstrap.value = await import('bootstrap');
      allServices.value = props.packageGroup.data.packages_by_subscription_type;
      initialize();
  });

</script>

<template>
  <AppLayout>
      <section class="buy-service-page">
          <div class="container">
              <h1 class="page-title mb-4">{{seoData.h1_title}}</h1>
              <SeoTopText />
              <div class="row justify-content-center mb-5">
                  <div class="col-12 col-lg-6">
                      <ul class="service-tabs nav nav-tabs" id="serviceTabs" role="tablist">
                          <li v-for="tab in tabs" :key="tab" class="nav-item" role="presentation">
                              <button
                                  :class="['nav-link', { 'active': tab === selectedTab }]"
                                  :id="`${tab}-tab`"
                                  data-bs-toggle="tab"
                                  :data-bs-target="`#${tab}-tab-pane`"
                                  type="button"
                                  role="tab"
                                  :aria-controls="`${tab}-tab-pane`"
                                  :aria-selected="tab === selectedTab"
                                  @click="selectedTab = tab"
                              >
                                  {{ tab }}
                              </button>
                          </li>
                      </ul>
                      <div class="service-card service-card--tabs c-card">
                          <div class="tab-content" id="myTabContent">
                              <div
                                  v-for="tab in tabs"
                                  :key="tab"
                                  class="tab-pane fade"
                                  :class="{ 'show active': tab === selectedTab }"
                                  :id="`${tab}-tab-pane`"
                                  role="tabpanel"
                                  :aria-labelledby="`${tab}-tab`"
                                  tabindex="0"
                              >
                                  <div class="row justify-content-center align-items-stretch g-2 mb-3">
                                      <div v-for="(item, index) in serviceList" :key="`service_${index}`" class="col-6 col-md-4">
                                          <div
                                              :class="['service-card-item', { 'service-card-item--active': item.checked }]"
                                              @click="handleSelectService(item,)"
                                          >
                                            <span v-for="(title, index) in item.titles" :key="`title_${index}_${title}`" class="service-card-item__text d-block">
                                              {{ title }}
                                            </span>
                                              <span  :class="[  'service-card-item__subtitle mt-2',  { 'service-card-item__subtitle--promo': index !== 0 }]">
                                              {{trans('front.save')}}  {{ item.discount }}%
                                               </span>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="service-card__content">
                                      <div class="service-price mb-3">
                                          <div class="d-flex flex-row justify-content-center align-items-center">
                                              <div class="service-price__title me-md-3 mb-0">${{ selectedService.price }}</div>
                                              <div class="d-flex flex-column align-items-md-start">
                                                  <span class="service-price__discount"> ${{ selectedService.base_price }} </span>
                                                  <span class="service-price__discount-price text-danger">
                                                    <span class="me-2">{{trans('front.you-save')}}</span>
                                                    <strong>${{selectedService.discountPrice }}</strong>
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                      <form
                                           :ref="validationForm => handleFormRef(validationForm, tab)"
                                          :key="tab" class="service-form mb-3" novalidate @submit.prevent="handleSubmit(tab)">
                                          <div v-if="formPaymentData.errors['packageId']" class="mb-3"  >
                                              {{ formPaymentData.errors['packageId'] }}
                                          </div>
                                          <div class="mb-3">
                                              <input
                                                  type="email"
                                                  class="form-control form-control-lg text-center"
                                                  :placeholder="trans('front.input_placeholder_email')"
                                                  required
                                                  v-model="formPaymentData['customerEmail']"
                                              />
                                              <div v-if="formPaymentData.errors['customerEmail']" class="invalid-feedback">
                                                  {{ formPaymentData.errors['customerEmail'] }}
                                              </div>
                                              <div v-if="formPaymentData.errors.customerData" class="invalid-feedback">
                                                  {{ formPaymentData.errors.customerData }}
                                              </div>
                                          </div>
                                          <div class="mb-3">
                                              <div v-for="(item, index) in packageGroup.data.input_fields" :key="index" class="mb-3">
                                                  <input
                                                      :type="item.type || 'text'"
                                                      class="form-control form-control-lg text-center 1is-invalid"
                                                      :placeholder="item.placeholder"
                                                      v-model="formPaymentData['customerData'][item.id].value"
                                                      :required="item.required || false"
                                                  />
                                                  <div v-if="formPaymentData.errors[`customerData.${item.id}.value`]" class="invalid-feedback">
                                                      {{ formPaymentData.errors[`customerData.${item.id}.value`] }}
                                                  </div>
                                              </div>
                                          </div>
                                          <button
                                              v-if="packageGroup.data.paymentSystemList.length"
                                              class="service-form__btn btn btn-lg btn-success"
                                              type="submit"
                                              :disabled="loading"
                                          >
                                              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                              <span v-if="!loading"> {{trans('front.get-it-now')}}</span>
                                              <span v-if="loading"> Processing...</span>
                                          </button>
                                      </form>

                                      <p class="service-card__terms">
                                          {{trans('front.term_part_1')}}
                                          <Link target="_blank" class="service-card__terms-link" :href="packageGroup.data.linkTerms || '#'">{{trans('front.term_part_2')}}</Link>
                                      </p>

                                      <div class="service-card__payments d-flex flex-wrap justify-content-center align-items-center mb-2">
                                          <img
                                              class="m-1 m-md-0 me-md-2"
                                              src="/img/icons/mastercard.svg"
                                              alt="Mastercard"
                                              width="50"
                                          />
                                          <img class="m-1 m-md-0 me-md-2" src="/img/icons/visa.svg" alt="Visa" width="50" />
                                          <img class="m-1 m-md-0 me-md-2" src="/img/icons/amex.svg" alt="Amex" width="50" />
                                          <img class="m-1 m-md-0 me-md-2" src="/img/icons/discover.svg" alt="Discover" width="50" />
                                          <img class="m-1 m-md-0 me-md-2" src="/img/icons/applepay.svg" alt="Applepay" width="50" />
                                          <img
                                              class="m-1 m-md-0 me-md-2"
                                              src="/img/icons/googlepay.svg"
                                              alt="Google pay"
                                              width="50"
                                          />
                                          <img class="m-1 m-md-0" src="/img/icons/bitcoin.svg" alt="Bitcoin" width="50" />
                                      </div>
                                  </div>

                                  <div class="service-card-features" v-if="!isAllFeatureEmpty">
                                      <ul class="service-card-features-list">
                                          <li
                                              v-for="(item, index) in packageGroup.data.feature_descriptions"
                                          :key="`feature_${index}_${item.title}`"
                                              :class="[ 'service-card-features-list-item d-flex align-items-center', { 'mb-3': index !== packageGroup.data.feature_descriptions.length - 1 }]"
                                          >
                                              <img class="me-2" :src="item.icon" :alt="item.title" width="32"/>
                                              {{ item.title }}
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <SeoTextBuyService />
              <div class="mb-5">
                <FAQ :faqs="packageGroup.data.faqs" :schema="packageGroup.data.schemaFaq"/>
              </div>
          </div>

          <WhatProvide />
          <WhyChoose />
          <HowWork />
          <HowBuy />

          <PayModal
              :paymentSystemList="packageGroup.data.paymentSystemList"
              :loading="paymentLoading"
              :formErrors="formPaymentData.errors"
              @submitForm="handlePaymentSubmit"
          />
          <DiscountModal />
          <Notification :product="packageGroup" />
      </section>
  </AppLayout>
    <Head>
        <component class="Product" is="script" type="application/ld+json" v-if="packageGroup.data.productSchema"  head-key="productSchema">
            {{ packageGroup.data.productSchema}}
        </component>
    </Head>
</template>

<style lang="scss" scoped></style>
