<script setup>
import { ref, onMounted, nextTick } from 'vue';

const props = defineProps({
    items: Array,
    collapsed: {
        type: Boolean,
        default: true
    },
    id: {
        type: String,
        default:'accordion_0'
    }
});

const activeIndexes = ref([]);

if (!props.collapsed) {
    activeIndexes.value = props.items.map((_, index) => index);
}

const isExpanded = (index) => {
    return activeIndexes.value.includes(index);
};

const toggleItem = async (index) => {
    const content = document.getElementById(`${props.id}_collapse_${index}`);
    const itemIndex = activeIndexes.value.indexOf(index);

    if (itemIndex === -1) {
        activeIndexes.value.push(index);
        await nextTick();
        content.style.maxHeight = content.scrollHeight + 'px';
    } else {
        content.style.maxHeight = content.scrollHeight + 'px';
        await nextTick();
        content.style.maxHeight = '0';
        activeIndexes.value.splice(itemIndex, 1);
    }
};

onMounted(() => {
    setTimeout(() => {
    activeIndexes.value.forEach(async (index) => {
        const content = document.getElementById(`${props.id}_collapse_${index}`);
        if (content) {
            await nextTick();
            content.style.maxHeight = content.scrollHeight + 'px';
        }
    });
    }, 500);
});
</script>

<template>
    <div class="accordion accordion-flush" :id="props.id">
        <div v-for="(item, index) in items" :key="`${props.id}_accordion_${index}`" class="accordion-item">
            <template v-if="item.question && item.answer">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button"
                        :class="{ 'collapsed': !isExpanded(index) }"
                        type="button"
                        @click="toggleItem(index)"
                        :aria-controls="`collapse_${index}`"
                        :aria-expanded="isExpanded(index)"
                    >
                        {{ item.question }}
                    </button>
                </h3>
                <div
                   :id="`${props.id}_collapse_${index}`"
                    class="accordion-collapse"
                    style="max-height: 0; transition: max-height 0.3s ease; overflow: hidden;"
                    :class="{ 'show': isExpanded(index) }"
                >
                    <div class="accordion-body">{{ item.answer }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<style>
.accordion-collapse {
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;
}

.accordion-collapse.show {
}
</style>

<style scoped lang="scss">
.accordion-button {
    font-size: 1.25rem;
    font-weight: 600;

    &:not(.collapsed) {
        color: #2d4472;
        background-color: transparent;
    }

    &:focus {
        box-shadow: none;
    }
}

.accordion-flush > .accordion-item:last-child {
    border-bottom: 0.0625rem solid #c8d4e0;
}

.accordion-header {
    font-size: 1.25rem;
}
</style>
