<script setup>
  import {ref, onMounted, computed  } from 'vue'
  import SeoTextBuyService from "@/Components/General/SeoTextBuyService.vue";
  import SeoTopText from "@/Components/General/SeoTopText.vue";
  import FAQ from "@/Components/General/FAQ.vue";
  import WhyChoose from "@/Components/General/WhyChoose.vue";
  import HowWork from "@/Components/General/HowWork.vue";
  import HowBuy from "@/Components/General/HowBuy.vue";
  import PayModal from "@/Components/Modals/PayModal.vue";
  import AppLayout from "@/Layout/AppLayout.vue";
  import { Link, usePage, useForm, Head } from '@inertiajs/vue3'
  import {trans} from "@/Utils/translation.utils.js";
  import Notification from '@/Components/General/Notification.vue';
  import LazyLoadComponent from "@/Components/LazyLoadComponent.vue";

  const props = defineProps({
      product: [Object, Array],
  })

  const seoData = usePage().props.seo_data
  const selectedPrice = ref(null)
  const singleView = ref(false)
  const isShowPriceDiscount = ref(false)
  const loading = ref(false);
  const paymentLoading = ref(false);
  const validationForm = ref();
  const bootstrap =  ref();

  const formatPrice = (num) => {
      return num ? Number(num).toFixed(2) : 0.00;
  };
  const handleSelectService = (item, index) => {
      selectedPrice.value = item;
      isShowPriceDiscount.value = index !== 0;
  }
  const formPaymentData = useForm({
      'customerEmail': null,
      'customerData': {},
      'productId': computed(() => selectedPrice.value ? selectedPrice.value.product_id : null),
      'price': computed(() => selectedPrice.value ? selectedPrice.value.price : null),
      'payment_system': null,
      'type':'product'
  })

  props.product.data.input_fields.forEach(field => {
      formPaymentData['customerData'][field.id] = {
          value: '',
          type: field.type,
          required: field.required,
          placeholder: field.placeholder,
          name: field.name
      };
  });

  function handleSubmit() {
      loading.value = true;
      formPaymentData.clearErrors();

      formPaymentData.post(route('payment.validate_customer_data'), {
          preserveState: true,
          onSuccess: () => {
              //   form.reset();
              openModalPayment()
              validationForm.value.classList.remove('was-validated');
          },
          onError: (errors) => {
              validationForm.value.classList.add('was-validated');
          },
          onFinish: () => {
              loading.value = false;
          },
      });
  }

  function handlePaymentSubmit(selectedPayment) {
      paymentLoading.value = true;
      formPaymentData.clearErrors();
      formPaymentData.payment_system = selectedPayment;

      formPaymentData.post(route('payment'), {
          preserveState: true,
          onSuccess: () => {
          },
          onError: (errors) => {
              paymentLoading.value = false;
          },
          onFinish: () => {

          },
      });
  }

  const isAllFeatureEmpty = computed(() => {
      return props.product.data.feature_descriptions.every(
          item => item.title === ""
      );
  })
  const openModalPayment = () => {
      if (typeof window !== 'undefined' && bootstrap.value) {
          let modalPayment = new bootstrap.value.Modal(document.getElementById('payModal'), {
              keyboard: false
          })

          modalPayment.show();
      }
  };

  const hasPaymentSystems = computed(() => {
      return props.product?.data?.paymentSystemList?.length > 0;
  });

  onMounted(async () => {
      if (props.product?.data?.prices?.length > 0) {
          selectedPrice.value = props.product.data.prices[0];
          singleView.value = props.product.data.prices.length === 1;
      }
      bootstrap.value = await import('bootstrap');
  });

</script>

<template>
  <AppLayout>
      <section class="buy-service-page">
          <div class="container">
              <h1 class="page-title mb-4">{{seoData.h1_title}}</h1>
              <SeoTopText />
              <div class="row justify-content-center mb-5">
                  <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                      <div class="service-card c-card">
                          <template v-if="!singleView">
                              <div class="row justify-content-center g-2 mb-3">
                                  <div v-for="(item, index) in product.data.prices" :key="index" class="col-4 col-md-3 mb-1">
                                      <div
                                          :class="['service-card-item', { 'service-card-item--active': item.id === selectedPrice?.id }]"
                                          @click="handleSelectService(item, index)"
                                      >
                                          <span class="service-card-item__title">{{ item.quantity }}</span>
                                          <span v-if="index !== 0" :class="['service-card-item__subtitle', { 'service-card-item__subtitle--promo': index !== 0 }]">
                                              {{trans('front.save')}} {{ item.percentageDifference }}%
                                          </span>
                                          <span v-else class="service-card-item__subtitle">{{product.data.product_type.plural_name}} </span>
                                      </div>
                                  </div>
                              </div>
                          </template>

                          <div class="service-promo mb-3" v-if="props.product.data.linkPackageGroup">
                              <div class="service-promo__label">
                                  <span>{{trans('front.get-cheaper-options')}}</span>
                              </div>
                              <Link class="service-promo__link"
                                    :href="`${props.product.data.linkPackageGroup || '#'}?utm_source=website&utm_medium=referral&utm_campaign=packages`">
                                  {{ trans('front.find-out-more') }}
                              </Link>
                          </div>

                          <div class="service-card__content">
                              <div class="service-price mb-3">
                                  <div class="service-price mb-3">
                                      <div class="d-flex flex-md-row justify-content-center align-items-center">
                                         <div class="service-price__title me-md-3 mb-0">${{ formatPrice(selectedPrice?.price) }}</div>
                                          <div v-if="isShowPriceDiscount || singleView" class="d-flex flex-column align-items-md-start">
                                              <span class="service-price__discount"> ${{ formatPrice(selectedPrice?.base_price) }} </span>
                                              <span class="service-price__discount-price text-danger">
                                                  <span class="me-2">{{trans('front.you-save')}}</span>
                                                  <strong>${{ selectedPrice?.priceDiscount }}</strong>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <div v-if="formPaymentData.errors['priceId']">
                                      {{ formPaymentData.errors['priceId'] }}
                                  </div>
                              </div>

                              <form ref="validationForm" class="service-form mb-3" novalidate @submit.prevent="handleSubmit">
                                  <div class="mb-1">
                                      <input
                                          type="email"
                                          class="form-control form-control-lg text-center"
                                          :placeholder="trans('front.input_placeholder_email')"
                                          v-model="formPaymentData['customerEmail']"
                                          required
                                      />
                                      <div v-if="formPaymentData.errors['customerEmail']" class="invalid-feedback">
                                          {{ formPaymentData.errors['customerEmail'] }}
                                      </div>
                                      <div v-if="formPaymentData.errors.customerData" class="invalid-feedback">
                                          {{ formPaymentData.errors.customerData }}
                                      </div>
                                  </div>

                                  <div v-for="(item) in product.data.input_fields" :key="item.id" class="mb-3">
                                      <input
                                          :type="item.type || 'text'"
                                          class="form-control form-control-lg text-center"
                                          :placeholder="item.placeholder"
                                          v-model="formPaymentData['customerData'][item.id].value"
                                          :required="item.required || false"
                                      />
                                      <div v-if="formPaymentData.errors[`customerData.${item.id}.value`]" class="invalid-feedback">
                                          {{ formPaymentData.errors[`customerData.${item.id}.value`] }}
                                      </div>
                                  </div>

                                  <button v-if="hasPaymentSystems" class="service-form__btn btn btn-lg btn-success" type="submit" :disabled="loading">
                                      <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      <span v-if="!loading"> {{trans('front.get-it-now')}}</span>
                                      <span v-if="loading"> Processing...</span>
                                  </button>
                              </form>

                              <p class="service-card__terms">
                                  {{trans('front.term_part_1')}}
                                  <Link target="_blank" class="service-card__terms-link" :href="product.data.linkTerms || '#'">{{trans('front.term_part_2')}}</Link>
                              </p>

                              <div class="service-card__payments d-flex flex-wrap justify-content-center align-items-center">
                                  <img class="mb-1 me-md-2" src="/img/icons/mastercard.svg" alt="Mastercard" width="50" />
                                  <img class="mb-1 me-md-2" src="/img/icons/visa.svg" alt="Visa" width="50" />
                                  <img class="mb-1 me-md-2" src="/img/icons/amex.svg" alt="Amex" width="50" />
                                  <img class="mb-1 me-md-2" src="/img/icons/discover.svg" alt="Discover" width="50" />
                                  <img class="mb-1 me-md-2" src="/img/icons/applepay.svg" alt="Applepay" width="50" />
                                  <img class="mb-1 me-md-2" src="/img/icons/googlepay.svg" alt="Google pay" width="50" />
                                  <img class="mb-1" src="/img/icons/bitcoin.svg" alt="Bitcoin" width="50" />
                              </div>
                          </div>
                          <div class="service-card-features mt-3" v-if="!isAllFeatureEmpty && product.data?.feature_descriptions?.length">
                              <ul class="service-card-features-list">
                                  <li
                                      v-for="(item, index) in product.data.feature_descriptions"
                                      :key="item.title"
                                      :class="[ 'service-card-features-list-item d-flex align-items-center',   { 'mb-3': index !== product.data.feature_descriptions.length - 1 }]"
                                  >
                                      <template v-if="item.title">
                                          <img class="me-2" :src="item.icon" :alt="item.title" width="32" />
                                          {{ item.title }}
                                      </template>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>

              <PayModal
                  :paymentSystemList="product.data.paymentSystemList"
                  :loading="paymentLoading"
                  :formErrors="formPaymentData.errors"
                  @submitForm="handlePaymentSubmit"
              />

              <SeoTextBuyService />
              <div class="mb-5">
                <FAQ :faqs="product.data.faqs" :schema="product.data.schemaFaq"/>
              </div>
          </div>


          <LazyLoadComponent>
              <WhyChoose />
          </LazyLoadComponent>

          <LazyLoadComponent>
              <HowWork />
          </LazyLoadComponent>

          <LazyLoadComponent>
              <HowBuy />
          </LazyLoadComponent>

          <Notification :product="product" />

      </section>
  </AppLayout>
    <Head>
        <component class="Product" is="script" type="application/ld+json" v-if="props.product.data.productSchema" head-key="productSchema">
              {{ props.product.data.productSchema}}
        </component>
   </Head>
   </template>

<style lang="scss" scoped></style>
