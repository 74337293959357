<script setup>
  import { computed, defineEmits } from 'vue'
  import Multiselect from '@vueform/multiselect'

  const props = defineProps({
      modelValue: {
          type: [String, Number],
          default: ''
      }
  })
  const emit = defineEmits(['update:modelValue', 'change'])

  const selectVal = computed({
    get() {
      return props.modelValue
    },
    set(newVal) {
      emit('update:modelValue', newVal)
      emit('change', newVal)
    }
  })
</script>

<template>
    <div class="custom-select">
        <Multiselect v-bind="$attrs" v-model="selectVal" :can-clear="false">
            <template #caret>
                <div class="custom-select__caret">
                    <img src="/img/icons/arrow.svg" alt="Arrow icon" width="16" height="16" />
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<style lang="scss">
:root {
    --ms-max-height: 26.25rem;
}

.custom-select {
    &__caret {
        margin-right: 0.625rem;
        transition: transform 0.2s linear;

        .bi {
            color: black;
        }
    }

    .multiselect {
        font-size: 1.25rem;
        min-height: 3rem;
        border: 0.0625rem solid #c8d4e0;
        border-radius: 0.5rem;

        .custom-select__caret {
            transform: rotate(90deg);
        }

        &.is-open {
            .custom-select__caret {
                transform: rotate(-90deg);
            }
        }

        &.is-active {
            border-color: #1e73be;
            box-shadow: 0 0 0 0.25rem rgba(#1e73be, 0.25);
        }

        &.is-error {
            border-color: #ff4a4a;
            box-shadow: 0 0 0 0.25rem rgba(#ff4a4a, 0.25);
        }

        &.is-success {
            border-color: #76cd68;
            box-shadow: 0 0 0 0.25rem rgba(#76cd68, 0.25);
        }
    }
    .multiselect-dropdown {
        padding: 0.5rem;
        bottom: -0.3125rem;
        border: 0.0625rem solid #c8d4e0;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
        overflow: hidden;
    }
    .multiselect-options {
        overflow: auto;
    }
    .multiselect-group-label {
        padding-left: 0;
        padding-right: 0;
        background: #fff;
        color: #141e33;
        font-size: 1.25rem;
        font-weight: 600;
    }
    .multiselect-option {
        padding: 0.5rem;
        color: #555555;
        font-size: 1.25rem;

        &.is-selected {
            background: #f5f5f5;
            color: #555555;
        }
    }
    .multiselect-placeholder {
        color: #8B98B1;
    }
    .multiselect-single-label-text {
        color: #555555;
    }
}
</style>
