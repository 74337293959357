<script setup>
    import { Link, Head, usePage } from '@inertiajs/vue3'
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";

    const homeUrl = usePage().props.homeUrl

</script>

<template>
    <AppLayout>
        <Head :title="trans('front.page_404_title')" />
        <section>
            <div class="container pb-5">
                <div class="not-found-card rounded-4 shadow p-4 text-center">
                    <h1 class="not-found-card__title page-title fw-normal lh-sm mb-3">404</h1>
                    <strong class="fs-5 lh-sm fw-semibold d-block mb-2">{{trans('front.page_404_title')}}!...</strong>
                    <p class="fs-5 lh-sm">{{trans('front.page_404_text')}}.</p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-5">
                            <div class="pt-2 pb-4">
                                <img class="mx-auto" src="/img/img-404.svg" alt="404 error" />
                            </div>
                            <div class="d-grid pt-2 pb-4">
                                <Link class="btn btn-lg btn-primary" :href="homeUrl">
                                    {{trans('front.page_404_link_content')}}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
    .not-found-card {
        background-color: #F5F5F5;
        border: 1px solid #C8D4E0;

        &__title {
            font-size: 3rem;
        }
    }
</style>
