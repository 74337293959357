<script setup>
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";
    import {Link, usePage} from '@inertiajs/vue3'
    import AlertComponent from '@/Components/Partials/Alert.vue'

    const homeUrl = usePage().props.homeUrl

    const props = defineProps({
        isFail: Boolean,
    })
</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <div v-if="isFail" class="text-danger p-4">
                    <AlertComponent type="danger" :message="trans('front_invoice.error')" />
                </div>
                <div v-else class="success-card rounded-4 shadow p-4 text-center">
                    <h1 class="success-card__title page-title fw-normal lh-sm mb-3">{{ trans('front_payment.success_title')}}</h1>
                    <p class="fs-5 lh-sm" v-html="trans('front_payment.success_text')"></p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-5">
                            <div class="pt-2 pb-4">
                                <img class="mx-auto" src="/img/img-success.svg" alt="Thank you for your order" />
                            </div>
                            <div class="d-grid pt-2 pb-4">
                                <Link :href="homeUrl" class="btn btn-lg btn-success no-underline">
                                    {{ trans('front_payment.success_link_text') }}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
    .success-card {
        color: #1E4F2C;
        background-color: #D4EFDC;
        border: 1px solid #C8D4E0;

        &__title {
            font-size: 3rem;
        }
    }

    .no-underline {
        text-decoration: none;
    }

    .no-underline:hover {
        text-decoration: none;
    }
</style>
