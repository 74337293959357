<script setup>
import SeoTextMain from '@/Components/General/SeoTextMain.vue'
import WhyChoose from '@/Components/General/WhyChoose.vue'
import HowWork from '@/Components/General/HowWork.vue'
import HowBuy from '@/Components/General/HowBuy.vue'
import WhyNeed from '@/Components/General/WhyNeed.vue'
import AppLayout from "@/Layout/AppLayout.vue";
import { Link, usePage } from '@inertiajs/vue3'
import {trans} from "@/Utils/translation.utils.js";
import LazyLoadComponent from "@/Components/LazyLoadComponent.vue";

const props = defineProps({
    popularEntities: Object,
    productCategories: Object
})
const seoData = usePage().props.seo_data

</script>

<template>
    <AppLayout>
        <section class="home-page">
        <div class="container">
            <h1 class="home-page__title mb-4 text-center">{{seoData.h1_title}}</h1>
            <div class="home-page-besteller mb-5">
                <h2 class="text-center mb-4">{{trans('front.bestsellers')}}</h2>
                <div class="row justify-content-center g-4">
                    <div v-for="item in popularEntities.data.filter((i) => i.is_bestseller)" :key="`bestseller-${item.id}`" class="col-12 col-md-6 col-lg-3">
                        <Link :class="`besteller-item besteller-item--${item.entity.social_media.slug}`"
                              :href="item.entity.link"
                        >
                            <img class="m-auto" :src="item.icon" width="64" height="64" v-if="item.icon" loading="lazy" alt="" />
                            <h3 class="besteller-item__title text-white">{{ item.entity.name }}</h3>
                        </Link>
                    </div>
                </div>
            </div>

            <div class="home-page-popular mb-5">
                <h2 class="text-center mb-4">{{trans('front.popular_social_media_services')}}</h2>
                <div class="row justify-content-center g-4">
                    <div
                        v-for="item in popularEntities.data.filter((i) => i.is_popular)"
                        :key="`popular_${item.id}`"
                        class="col-12 col-md-4 col-lg-3 col-xxl-2"
                    >
                        <Link class="popular-item c-card c-card--hovered" :href="item.entity.link">
                            <img class="m-auto" :src="item.icon" width="72" v-if="item.icon" loading="lazy" alt=""/>
                            <span class="popular-item__title">{{ item.entity.name }}</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div class="home-page-categories mb-5">
                <h2 class="text-center mb-4">{{trans('front.product_categories')}}</h2>
                <div class="row justify-content-center g-4">
                    <div
                        v-for="item in productCategories.data"
                        :key="`category_${item.slug}`"
                        class="col-12 col-md-4 col-lg-3 col-xxl-2"
                    >
                        <Link class="category-item" :href="item.entity.link">
                            <img class="m-auto" :src="item.icon" width="48" loading="lazy" alt=""/>
                            <span class="popular-item__title">{{ item.entity.name }}</span>
                        </Link>
                    </div>
                </div>
            </div>
            <SeoTextMain />
        </div>

        <LazyLoadComponent>
            <WhyChoose />
        </LazyLoadComponent>

        <LazyLoadComponent>
            <HowWork />
        </LazyLoadComponent>

        <LazyLoadComponent>
            <HowBuy />
        </LazyLoadComponent>

        <LazyLoadComponent>
            <WhyNeed />
        </LazyLoadComponent>
    </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
.home-page {
    padding-top: 1.25rem;

    &__title {
        font-weight: 800;
    }
}
.besteller-item {
    display: block;
    border-radius: 1rem;
    padding: 1.5rem;
    font-family: 'Ruda', 'Montserrat', sans-serif;
    text-align: center;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
    transition: box-shadow 0.25s ease;

    &:hover {
        text-decoration: none;
        box-shadow: none;
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 800;
        margin-top: 1rem;
    }

    &--instagram {
        background: radial-gradient(
                73.06% 74.69% at 19.84% 118.12%,
                #ffd600 0,
                #ff6930 48.44%,
                #fe3b36 73.44%,
                rgba(254, 59, 54, 0) 100%
        ),
        radial-gradient(
                182.65% 122.8% at 84.5% 113.5%,
                #ff1b90 23.96%,
                #f80261 43.67%,
                #ed00c0 68.85%,
                #c500e9 77.68%,
                #7017ff 89.32%
        );
    }

    &--facebook {
        background-color: #1877f2;
    }

    &--youtube {
        background-color: #fd151b;
    }

    &--tiktok {
        background-color: #000;
    }
}

.popular-item {
    display: block;
    text-align: center;
    padding: 1.5rem 1rem;
    height: 100%;
    font-family: 'Ruda', 'Montserrat', sans-serif;
    transition: box-shadow 0.25s ease;

    &:hover {
        text-decoration: none;
        box-shadow: none;
    }

    &__title {
        display: block;
        font-size: 1rem;
        font-weight: 800;
        margin-top: 0.5rem;
        width: calc(100% + 0.0625rem);
    }
}

.category-item {
    display: block;
    text-align: center;
    padding: 1rem;
    border: 0.0625rem solid #c8d4e0;
    border-radius: 1rem;
    height: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
    font-family: 'Ruda', 'Montserrat', sans-serif;
    transition: box-shadow 0.25s ease;

    &:hover {
        text-decoration: none;
        box-shadow: none;
    }

    &__title {
        display: block;
        font-size: 1rem;
        font-weight: 800;
        margin-top: 0.5rem;
    }
}

.seo-text {
    margin-bottom: 4rem;
}
</style>
